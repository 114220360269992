import React, { useState } from "react";

const Faq = () => {
    return <section>
                <h3 className="col-lg-12  mb-3">FREQUENTLY ASKED QUESTIONS</h3>
                <div className="my-properties">
                    <div className="col-lg-12 col-md-12">
                        <ul className="accordion accordion-1 one-open">
                            <li className="active">
                                <div className="title">
                                    <span>What payment methods are available?</span>
                                </div>
                                <div className="content">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="title">
                                    <span>How can i get findhouses aid to live off campus?</span>
                                </div>
                                <div className="content">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="title">
                                    <span>Does findhouses share my information with others?</span>
                                </div>
                                <div className="content">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="title">
                                    <span>What kind of real estate advice do you give?</span>
                                </div>
                                <div className="content">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="title">
                                    <span>How do i link multiple accounts with my profile?</span>
                                </div>
                                <div className="content">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </p>
                                </div>
                            </li>
                           
                          
                        </ul>
                    </div>
                </div>
    </section>
    }

export default Faq;