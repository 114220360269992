export const columnsDataColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Username",
    accessor: "user_name",
  },
  {
    Header: "Password",
    accessor: "password",
  },
  {
    Header: "ACTION",
  },
];
