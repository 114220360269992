export const columnsDataColumns = [
  {
    Header: "Category",
    accessor: "category_name",
  },
  {
    Header: "Image",
    accessor: "file",
  },
  {
    Header: "ACTION",
  },
];

