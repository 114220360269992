import React, { useEffect, useState } from "react";
import Footer from "../common/footer";
import Header from "../common/header";
import LeftBar from "../common/leftbar";
import "../common/css/jquery-ui.css";
import "../common/css/fontawesome-all.min.css";
import "../common/css/fontawesome-5-all.min.css";
import "../common/css/font-awesome.min.css";
import "../common/css/search.css";
import "../common/css/animate.css";
import "../common/css/aos2.css";
import "../common/css/swiper.min.css";
import "../common/css/magnific-popup.css";
import "../common/css/lightcase.css";
import "../common/css/bootstrap.min.css";
import "../common/css/menu.css";
import "../common/css/default.css";
import "../common/css/styles.css";
import axios from "axios";

const Amenitis = () => {
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAmenitiesData();
  }, []);
  const getAmenitiesData = () => {
    axios
      .get("${process.env.REACT_APP_BASE_URL}/amenities")
      .then((data) => {
        setAmenitiesList(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  console.log("-------------------------41amenitiesList", amenitiesList);
  return (
    <div className="inner-pages int_white_bg">
      <LeftBar />
      <Header />

      <section className="headings">
        <div className="text-heading text-center">
          <div className="container">
            <h1>Amenitis</h1>
            <h2>
              <a href="index.html">Home </a> &nbsp;/&nbsp; Amenitis
            </h2>
          </div>
        </div>
      </section>
      {/* <!-- END SECTION HEADINGS --> */}

      <section
        className="services-home rec-pro"
        style={{ paddingBottom: "10% !important", paddingBottom: 40 }}
      >
        <div className="container" style={{ maxWidth: "85%" }}>
          <center>
            <h4
              style={{
                fontFamily: "'Montserrat', sans-serif",
                fontSize: 18,
                color: "#333",
                fontWeight: 600,
              }}
            >
              LOFTS@JC is centrally located in the heart of the Tri-Cities area,
              close to all major business and retail centers: a block away from
              the new Binghamton University Health Sciences Campus, blocks away
              from the UHS-Wilson Medical Center, and is a 5-10 minute driving
              distance from Downtown Binghamton and the Binghamton University
              Main Campus.
            </h4>
          </center>
          <div
            className="row"
            style={{
              marginTop: 90,
            }}
          >
            {amenitiesList?.length > 0 &&
              amenitiesList.map((idata, idx) => {
                return (
                  <div
                    className="col-lg-3 col-md-12"
                    style={{
                      opacity: "1 !important",
                      dataAos: "fade-up",
                      dataAosDelay: idx === 0 ? 150 : 150 + 100,
                    }}
                    key={idx}
                  >
                    <div className="amenities-service service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                      <div className="media">
                        <i className="fa fa-home bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                      </div>
                      <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                        <h4 className="m-bottom-15 text-bold-700">
                          {idata.title}
                        </h4>
                        <p>{idata.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}

            {/* <div
              className="col-lg-3 col-md-12 m-top-40 m-bottom-40"
              style={{
                opacity: "1 !important",
                dataAos: "fade-up",
                dataAosDelay: "250",
              }}
            >
              <div className="service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                <div className="media">
                  <i className="fas fa-building bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                </div>
                <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                  <h4 className="m-bottom-15 text-bold-700">STUDY LOUNGE</h4>
                  <p>Private study lounge with seating for eight.</p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-12 m-top-40 m-bottom-40 commercial"
              style={{
                opacity: "1 !important",
                dataAos: "fade-up",
                dataAosDelay: "350",
              }}
            >
              <div className="service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                <div className="media">
                  <i className="fas fa-warehouse bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                </div>
                <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                  <h4 className="m-bottom-15 text-bold-700">LAUNDRY</h4>
                  <p>Washer and dryer included in every unit</p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-12 m-top-40 m-bottom-40 commercial"
              style={{
                opacity: "1 !important",
                dataAos: "fade-up",
                dataAosDelay: "450",
              }}
            >
              <div className="service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                <div className="media">
                  <i className="fas fa-hotel bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                </div>
                <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                  <h4 className="m-bottom-15 text-bold-700">FURNITURE</h4>
                  <p>
                    Fully furnished bedrooms including full size bed and
                    mattress, individual desk and chair, and oversized closet
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-12 m-top-40 m-bottom-40 commercial"
              style={{
                opacity: "1 !important",
                dataAos: "fade-up",
                dataAosDelay: "450",
                marginTop: "60px",
              }}
            >
              <div className="service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                <div className="media">
                  <i className="fas fa-hotel bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                </div>
                <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                  <h4 className="m-bottom-15 text-bold-700">FITNESS CENTER</h4>
                  <p>
                    Free access to exclusive state-of-the-art fitness center in
                    building.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-12 m-top-40 m-bottom-40 commercial"
              style={{
                opacity: "1 !important",
                dataAos: "fade-up",
                dataAosDelay: "450",
                marginTop: "60px",
              }}
            >
              <div className="service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                <div className="media">
                  <i className="fas fa-hotel bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                </div>
                <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                  <h4 className="m-bottom-15 text-bold-700">APPLIANCES</h4>
                  <p>
                    Stainless steel oven, refrigerator, dishwasher, microwave,
                    and ample cabinets.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-12 m-top-40 m-bottom-40 commercial"
              style={{
                opacity: "1 !important",
                dataAos: "fade-up",
                dataAosDelay: "450",
                marginTop: "60px",
              }}
            >
              <div className="service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                <div className="media">
                  <i className="fas fa-hotel bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                </div>
                <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                  <h4 className="m-bottom-15 text-bold-700">UTILITIES</h4>
                  <p>
                    Internet’High-speed managed WiFi/Stream TV and all other
                    utilities included.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-12 m-top-40 m-bottom-40 commercial"
              style={{
                opacity: "1 !important",
                dataAos: "fade-up",
                dataAosDelay: "450",
                marginTop: "60px",
              }}
            >
              <div className="service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                <div className="media">
                  <i className="fas fa-hotel bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                </div>
                <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                  <h4 className="m-bottom-15 text-bold-700">LOCATION</h4>
                  <p>
                    Centrally located in the Tri-Bros-City area, next to two BU
                    campuses, the UHS-Wilson Medical Center.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Amenitis;
