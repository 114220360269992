import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
} from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin/adminRoutes";
import RTLLayout from "layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { userRoutes } from "./userRoutes";
import { Provider } from "react-redux";
import { store } from "./redux/store";

ReactDOM.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      {/* <React.StrictMode> */}
      <BrowserRouter>
        {/* <ThemeEditorProvider> */}
        {/* <HashRouter> */}
        <Switch>
          <Route path={`/auth`} component={AuthLayout} />
          <Route path={`/admin`} component={AdminLayout} />
          {/* <Route path={`/rtl`} component={RTLLayout} /> */}
          {userRoutes.map((route) => (
            <Route
              key={route.path}
              exact
              path={route.path}
              component={route.component}
            />
          ))}
          {/* <Redirect from='/' to='/admin' /> */}
        </Switch>
        {/* </HashRouter> */}
        {/* </ThemeEditorProvider> */}
      </BrowserRouter>
      {/* </React.StrictMode> */}
    </ChakraProvider>
  </Provider>,
  document.getElementById("root")
);
