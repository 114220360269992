const LeftBar = (props) => {
  return (
    <div
      className="int_infosidebar"
      style={{
        background: "#fff",
      }}
    >
      <div className="siderbar_social">
        <ul className="p-0">
          <li>
            <a
              href="javascript:;"
              style={{
                color: "#333",
              }}
            >
              <i className="fa fa-facebook"></i>
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              style={{
                color: "#333",
              }}
            >
              <i className="fab fa-instagram"></i>
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              style={{
                color: "#333",
              }}
            >
              <i className="fab fa-twitter"></i>
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              style={{
                color: "#333",
              }}
            >
              <i className="fab fa-behance"></i>
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              style={{
                color: "#333",
              }}
            >
              <i className="fab fa-dribbble"></i>
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              style={{
                color: "#333",
              }}
            >
              <i className="fab fa-pinterest-p"></i>
            </a>
          </li>
        </ul>
      </div>
      <div
        className="siderbar_contact"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <a
          href="javascript:;"
          style={{
            color: "#333",
            display: "flex",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 480.56 480.56"
            width="18px"
            height="18px"
          >
            <g>
              <g>
                <g>
                  <path
                    d="M365.354,317.9c-15.7-15.5-35.3-15.5-50.9,0c-11.9,11.8-23.8,23.6-35.5,35.6c-3.2,3.3-5.9,4-9.8,1.8    c-7.7-4.2-15.9-7.6-23.3-12.2c-34.5-21.7-63.4-49.6-89-81c-12.7-15.6-24-32.3-31.9-51.1c-1.6-3.8-1.3-6.3,1.8-9.4    c11.9-11.5,23.5-23.3,35.2-35.1c16.3-16.4,16.3-35.6-0.1-52.1c-9.3-9.4-18.6-18.6-27.9-28c-9.6-9.6-19.1-19.3-28.8-28.8    c-15.7-15.3-35.3-15.3-50.9,0.1c-12,11.8-23.5,23.9-35.7,35.5c-11.3,10.7-17,23.8-18.2,39.1c-1.9,24.9,4.2,48.4,12.8,71.3    c17.6,47.4,44.4,89.5,76.9,128.1c43.9,52.2,96.3,93.5,157.6,123.3c27.6,13.4,56.2,23.7,87.3,25.4c21.4,1.2,40-4.2,54.9-20.9    c10.2-11.4,21.7-21.8,32.5-32.7c16-16.2,16.1-35.8,0.2-51.8C403.554,355.9,384.454,336.9,365.354,317.9z"
                    data-original="#000000"
                    className="active-path"
                    data-old_color="#000000"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M346.254,238.2l36.9-6.3c-5.8-33.9-21.8-64.6-46.1-89c-25.7-25.7-58.2-41.9-94-46.9l-5.2,37.1    c27.7,3.9,52.9,16.4,72.8,36.3C329.454,188.2,341.754,212,346.254,238.2z"
                    data-original="#000000"
                    className="active-path"
                    data-old_color="#000000"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M403.954,77.8c-42.6-42.6-96.5-69.5-156-77.8l-5.2,37.1c51.4,7.2,98,30.5,134.8,67.2c34.9,34.9,57.8,79,66.1,127.5    l36.9-6.3C470.854,169.3,444.354,118.3,403.954,77.8z"
                    data-original="#000000"
                    className="active-path"
                    data-old_color="#000000"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
            </g>
          </svg>
          Call +4(204) 870-01785
        </a>
      </div>
      <div
        className="siderbar_email"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <a
          href="javascript:;"
          style={{
            color: "#333",
            display: "flex",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18px"
            height="18px"
            viewBox="0 0 485.211 485.211"
          >
            <g>
              <g>
                <path
                  d="M485.211,363.906c0,10.637-2.992,20.498-7.785,29.174L324.225,221.67l151.54-132.584   c5.895,9.355,9.446,20.344,9.446,32.219V363.906z M242.606,252.793l210.863-184.5c-8.653-4.737-18.397-7.642-28.908-7.642H60.651   c-10.524,0-20.271,2.905-28.889,7.642L242.606,252.793z M301.393,241.631l-48.809,42.734c-2.855,2.487-6.41,3.729-9.978,3.729   c-3.57,0-7.125-1.242-9.98-3.729l-48.82-42.736L28.667,415.23c9.299,5.834,20.197,9.329,31.983,9.329h363.911   c11.784,0,22.687-3.495,31.983-9.329L301.393,241.631z M9.448,89.085C3.554,98.44,0,109.429,0,121.305v242.602   c0,10.637,2.978,20.498,7.789,29.174l153.183-171.44L9.448,89.085z"
                  data-original="#000000"
                  className="active-path"
                  data-old_color="#000000"
                  fill="#FFFFFF"
                />
              </g>
            </g>
          </svg>
          info@lofts.com
        </a>
      </div>
    </div>
  );
};

export default LeftBar;
