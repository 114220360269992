import Home from "views/user/home";
import About from "views/user/about";
import Amenitis from "views/user/amenities";
import Contact from "views/user/contact";
import Gallery from "views/user/gallery";
import Profile from "views/user/profile";
import Booking from "views/user/booking";
import Blog from "views/admin/Blog/blog";
import Property from "views/admin/property/prpperty";
import Complaints from "views/admin/complaints/complaints";
import Bulding from "views/user/bulding/bulding";
import BuldingDetails from "views/user/buldingDetails/buldingDetails";
import FlatDetails from "views/user/flatDetails/flatdetails";
import BookingForm from "views/user/booking Details/bookingDetails";
import EditFlat from "views/admin/editFlat/editflat";

export const userRoutes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/amenities",
    component: Amenitis,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/gallery",
    component: Gallery,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/booking",
    component: Booking,
  },
  {
    path: "/blog",
    component: Blog,
  },
  {
    path: "/property",
    component: Property,
  },
  {
    path: "/building",
    component: Bulding,
  },
  {
    path: "/buildingDetails",
    component: BuldingDetails,
  },
  {
    path: "/flatDetails",
    component: FlatDetails,
  },
  {
    path: "/bookingForm",
    component: BookingForm,
  },
  // {
  //   path: "/editFlat",
  //   component: EditFlat,
  // },
  // {
  //   path: "/complaints",
  //   component: Complaints,
  // }
  // add more routes here as needed
];
