import Footer from "../common/footer";
import Header from "../common/header";
import LeftBar from "../common/leftbar";
import OwlCarousel from "react-owl-carousel";

import drone5 from "../common/images/site/drone5.png";
import ts1 from "../common/images/testimonials/ts-1.jpg";
import ts2 from "../common/images/testimonials/ts-2.jpg";
import ts3 from "../common/images/testimonials/ts-3.jpg";
import ts4 from "../common/images/testimonials/ts-4.jpg";
import ts5 from "../common/images/testimonials/ts-5.jpg";
import ts6 from "../common/images/testimonials/ts-6.jpg";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../common/css/jquery-ui.css";
import "../common/css/fontawesome-all.min.css";
import "../common/css/fontawesome-5-all.min.css";
import "../common/css/font-awesome.min.css";
import "../common/css/search.css";
import "../common/css/animate.css";
import "../common/css/aos2.css";
import "../common/css/swiper.min.css";
import "../common/css/magnific-popup.css";
import "../common/css/lightcase.css";
import "../common/css/bootstrap.min.css";
import "../common/css/menu.css";
import "../common/css/default.css";
import "../common/css/styles.css";

const options = {
  items: true,
  // loop: true,
  // nav: true,
  dots: false,
  // autoplay: true,
  autoplayTimeout: 5000,
  smartSpeed: 1000,
  navText: ["", ""],
};

const propertyList = [
  {
    name: "Lisa Smith",
    place: "New York",
    image: ts1,
  },
  {
    name: "Jhon Morris",
    place: "Los Angeles",
    image: ts2,
  },
  {
    name: "Mary Deshaw",
    place: "Chicago",
    image: ts3,
  },
  {
    name: "Gary Steven",
    place: "Philadelphia",
    image: ts4,
  },
  {
    name: "Cristy Mayer",
    place: "San Francisco",
    image: ts5,
  },
  {
    name: "Ichiro Tasaka",
    place: "Houston",
    image: ts6,
  },
];

const AboutUs = () => {
  return (
    <div className="inner-pages int_white_bg">
      <LeftBar />
      <Header />

      <section className="headings">
        <div className="text-heading text-center">
          <div className="container">
            <h1>About Our Company</h1>
            <h2>
              <a href="index.html">Home </a> &nbsp;/&nbsp; About Us
            </h2>
          </div>
        </div>
      </section>
      {/* <!-- END SECTION HEADINGS --> */}

      {/* <!-- START SECTION ABOUT --> */}
      <section className="about-us fh">
        <div className="container" style={{ maxWidth: "85%" }}>
          <div className="row">
            <div className="col-lg-6 col-md-12 who-1">
              <div>
                <h2
                  className="text-left mb-4"
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    color: "#222",
                    fontSize: "2rem",
                    fontWeight: 800,
                    textTransform: "uppercase",
                  }}
                >
                  About <span>Lofts@jc</span>
                </h2>
              </div>
              <div className="pftext">
                <p>
                  LOFTS@JC is centrally located in the heart of the Tri-Cities
                  area, close to all major business and retail centers: a block
                  away from the new Binghamton University Health Sciences
                  Campus, blocks away from the UHS-Wilson Medical Center, and is
                  a 5-10 minute driving distance from Downtown Binghamton and
                  the Binghamton University Main Campus.
                </p>

                <p>
                  LOFTS@JC offers luxury housing with no compromises! The
                  complex has beautiful one, two and three bedroom units, each
                  bedroom with its own private bathroom, modern furnishings,
                  granite countertops, top-of-the-line black stainless steel
                  appliances, and other amenities galore. Each room has its own
                  individualized temperature control. We have ADA-accessible
                  units, and each unit comes with access to our state-of-the-art
                  internet/high-speed managed WiFi/Stream TV package, and all
                  utilities are included in the same price! The building has a
                  modern elevator, and offers gated, secure surface parking,
                  with ample lights and electronic FOB entry systems for optimal
                  security and sound of mind.
                </p>
              </div>
              <div className="box bg-2">
                <a
                  href="about.html"
                  className="text-center button button--moema button--text-thick button--text-upper button--size-s"
                >
                  read More
                </a>
                {/* <!-- <img src="images/signature.png" className="ml-5" alt=""> --> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-xs-12">
              <div className="wprt-image-video w50">
                <img alt="image" src={drone5} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- START SECTION COUNTER UP --> */}
      <section className="counterup" style={{ marginTop: "5%" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-xs-12">
              <div className="countr">
                <i className="fa fa-home" aria-hidden="true"></i>
                <div className="count-me">
                  <p className="counter text-left">300</p>
                  <h3>Sold Houses</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12">
              <div className="countr">
                <i className="fa fa-list" aria-hidden="true"></i>
                <div className="count-me">
                  <p className="counter text-left">400</p>
                  <h3>Daily Listings</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12">
              <div className="countr mb-0">
                <i className="fa fa-users" aria-hidden="true"></i>
                <div className="count-me">
                  <p className="counter text-left">250</p>
                  <h3>Expert Agents</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12">
              <div className="countr mb-0 last">
                <i className="fa fa-trophy" aria-hidden="true"></i>
                <div className="count-me">
                  <p className="counter text-left">200</p>
                  <h3>Won Awars</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- END SECTION COUNTER UP --> */}

      {/* <!-- START SECTION TESTIMONIALS --> */}
      <section className="testimonials home18 bg-white">
        <div className="container">
          <div className="sec-title">
            <h2>
              <span>Clients </span>Testimonials
            </h2>
            <p>We collect reviews from our customers.</p>
          </div>

          <OwlCarousel {...options}>
            {propertyList.map((property, index) => (
              <div className="test-1 pb-0 pt-0">
                <img src={property.image} alt="" />
                <h3 className="mt-3 mb-0">{property.name}</h3>
                <h6 className="mt-1">"{property.place}"</h6>
                <ul className="starts text-center mb-2">
                  <li>
                    <i className="fa fa-star"></i>
                  </li>
                  <li>
                    <i className="fa fa-star"></i>
                  </li>
                  <li>
                    <i className="fa fa-star"></i>
                  </li>
                  <li>
                    <i className="fa fa-star"></i>
                  </li>
                  <li>
                    <i className="fa fa-star"></i>
                  </li>
                </ul>
                <p>
                  Lorem ipsum dolor sit amet, ligula magna at etiam aliquip
                  venenatis. Vitae sit felis donec, suscipit tortor et sapien
                  donec.
                </p>
              </div>
            ))}
          </OwlCarousel>

          {/* <div className="owl-carousel style1">
            <div className="test-1 pb-0 pt-0">
              <img src="images/testimonials/ts-2.jpg" alt="" />
              <h3 className="mt-3 mb-0">Jhon Morris</h3>
              <h6 className="mt-1">Los Angeles</h6>
              <ul className="starts text-center mb-2">
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star-o"></i>
                </li>
              </ul>
              <p>
                Lorem ipsum dolor sit amet, ligula magna at etiam aliquip
                venenatis. Vitae sit felis donec, suscipit tortor et sapien
                donec.
              </p>
            </div>
            <div className="test-1 pt-0">
              <img src="images/testimonials/ts-3.jpg" alt="" />
              <h3 className="mt-3 mb-0">Mary Deshaw</h3>
              <h6 className="mt-1">Chicago</h6>
              <ul className="starts text-center mb-2">
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
              </ul>
              <p>
                Lorem ipsum dolor sit amet, ligula magna at etiam aliquip
                venenatis. Vitae sit felis donec, suscipit tortor et sapien
                donec.
              </p>
            </div>
            <div className="test-1 pt-0">
              <img src="images/testimonials/ts-4.jpg" alt="" />
              <h3 className="mt-3 mb-0">Gary Steven</h3>
              <h6 className="mt-1">Philadelphia</h6>
              <ul className="starts text-center mb-2">
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star-o"></i>
                </li>
              </ul>
              <p>
                Lorem ipsum dolor sit amet, ligula magna at etiam aliquip
                venenatis. Vitae sit felis donec, suscipit tortor et sapien
                donec.
              </p>
            </div>
            <div className="test-1 pt-0">
              <img src="images/testimonials/ts-5.jpg" alt="" />
              <h3 className="mt-3 mb-0">Cristy Mayer</h3>
              <h6 className="mt-1">San Francisco</h6>
              <ul className="starts text-center mb-2">
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
              </ul>
              <p>
                Lorem ipsum dolor sit amet, ligula magna at etiam aliquip
                venenatis. Vitae sit felis donec, suscipit tortor et sapien
                donec.
              </p>
            </div>
            <div className="test-1 pt-0">
              <img src="images/testimonials/ts-6.jpg" alt="" />
              <h3 className="mt-3 mb-0">Ichiro Tasaka</h3>
              <h6 className="mt-1">Houston</h6>
              <ul className="starts text-center mb-2">
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star-o"></i>
                </li>
              </ul>
              <p>
                Lorem ipsum dolor sit amet, ligula magna at etiam aliquip
                venenatis. Vitae sit felis donec, suscipit tortor et sapien
                donec.
              </p>
            </div>
          </div> */}
        </div>
      </section>
      {/* <!-- END SECTION TESTIMONIALS --> */}
      <Footer />
    </div>
  );
};

export default AboutUs;
