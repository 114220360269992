import {
  Flex,
  Th,
  Thead,
  Tr,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";

const TableHeader = (props) => {
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  return (
    <Thead>
      {props.headerGroups.map((headerGroup, index) => (
        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
          {headerGroup.headers.map((column, index) => (
            <Th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              pe="10px"
              key={index}
              borderColor={borderColor}
            >
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                {column.render("Header")}
                {column.Header === "ACTION" && (
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={props.addClick}
                    style={{
                      margin: " 0px",
                      display: "flex",
                      width: "auto",
                      height: "25px",
                      fontSize: "12px",
                      padding: "5px",
                      lineHeight: "19px",
                    }}
                  >
                    {props.headerAddText || "Add"}
                  </Button>
                )}
              </Flex>
            </Th>
          ))}
        </Tr>
      ))}
    </Thead>
  );
};

export default TableHeader;
