import React, { useMemo, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";
// Custom components
import Card from "components/card/Card";
import TableHeader from "components/tableHeader";
import AdminSidebar from "layouts/admin";
import { MDBDataTableV5 } from "mdbreact";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IconButton } from "@chakra-ui/react";
import "react-responsive-modal/styles.css";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import DatePicker from "react-datepicker";

export default function MinimunStay({}) {
  const [open, setOpen] = useState(false);
  const [myStayData, setMyStayData] = useState({
    floorId: "",
    flatNo: "",
    type: "",
    description: "",
    cleaningFees: "",
    startDate: "",
    endDate: "",
    bathrooms: "",
    images: [],
    price: "",
  });
  const [currentStayId, setCurrentStayId] = useState(null);
  const [Editopen, setEditOpen] = useState(false);
  const onOpenModal = () => {
    console.log("coing insifde");
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "No",
        field: "no",
        width: 270,
      },
      {
        label: "Start Date",
        field: "start_date",
        width: 150,
      },
      {
        label: "End Date",
        field: "end_date",
        width: 100,
      },

      {
        label: "Days",
        field: "days",
        width: 100,
      },

      {
        label: "Price",
        field: "price",
        width: 100,
      },
      {
        label: "Action",
        field: "actions",
        width: 100,
      },
    ],
  });

  const getMinimumStaysData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/minimum-stay`)
      .then((response) => {
        console.log(response, "response");
        const data = response.data.staysData.map((item, idx) => {
          // Convert buffer array to base64 string
          console.log(item, "item");

          return {
            no: idx + 1,
            start_date: moment(item.start_date).format("DD/MM/YYYY"),
            end_date: moment(item.end_date).format("DD/MM/YYYY"),
            days: item.selected_days,
            price: (
              <div>
                <p style={{ display: "flex" }}>
                  1 Bed / 1 Bath: &nbsp; <strong>{item.category_1}$</strong>
                </p>
                <p style={{ display: "flex" }}>
                  2 Bed / 2 Bath: &nbsp; <strong>{item.category_2}$</strong>
                </p>
                <p style={{ display: "flex" }}>
                  3 Bed / 3 Bath: &nbsp; <strong>{item.category_3}$</strong>
                </p>
                <p style={{ display: "flex" }}>
                  Deluxe 1 Bed / 2 Bath / Home Office: &nbsp;{" "}
                  <strong>{item.category_4}$</strong>
                </p>
                <p style={{ display: "flex" }}>
                  Deluxe 2 Bed / 3 Bath / Home Office: &nbsp;{" "}
                  <strong>{item.category_5}$</strong>
                </p>
              </div>
            ),
            actions: (
              <div>
                <button className="mx-3" onClick={() => handleEdit(item.id)}>
                  <IconButton
                    icon={<EditIcon />}
                    aria-label="Edit"
                    size="sm"
                    colorScheme="blue"
                    mr="2"
                  />
                </button>
                <button onClick={() => handleDelete(item.id)}>
                  <IconButton
                    icon={<DeleteIcon />}
                    aria-label="Delete"
                    size="sm"
                    colorScheme="blue"
                    mr="2"
                  />
                </button>
              </div>
            ),
          };
        });

        setDatatable((prevState) => ({
          ...prevState,
          rows: data,
        }));
      });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/minimum-stay/${id}`)
          .then((response) => {
            getMinimumStaysData();
            Swal.fire("Deleted!", "Your Stay has been deleted.", "success");
            console.log(`Blog with ID ${id} deleted successfully.`);
            console.log(response.data.message);
            // Optionally, you can update the datatable state to reflect the updated data without the deleted blog.
          })
          .catch((error) => {
            Swal.fire(
              "Error",
              "An error occurred while deleting the file.",
              "error"
            );
            console.error(`Error deleting blog with ID ${id}:`, error);
          });
      }
    });
  };

  const handleEdit = (id) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/minimum-stay/${id}`)
      .then((response) => {
        console.log("response", response);
        const stayData = response.data.stayData;

        setMyStayData({
          start_date: moment(stayData.start_date).format("YYYY-MM-DD"),
          end_date: moment(stayData.end_date).format("YYYY-MM-DD"),
          selected_days: stayData.selected_days,
          category_1: stayData.category_1,
          category_2: stayData.category_2,
          category_3: stayData.category_3,
          category_4: stayData.category_4,
          category_5: stayData.category_5,
        });
        onOpenModal();
        setCurrentStayId(id);
        setEditOpen(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const initialValues = {
    start_date: "",
    end_date: "",
    selected_days: "",
    category_1: "",
    category_2: "",
    category_3: "",
    category_4: "",
    category_5: "",
  };
  const [formState, setFormState] = useState(initialValues);

  const addMinimumStayHandler = (values) => {
    console.log("values", values);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/minimum-stay`, values)
      .then((response) => {
        getMinimumStaysData();
        onCloseModal();
        // Optionally, you can update the datatable state to reflect the updated data without the deleted blog.
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Minimum Stay Added Succesfully",
          showConfirmButton: false,
          timer: 1500,
        });
        setFormState(null);
        // setFieldValue("")
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const editHandler = (values) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/minimum-stay/${currentStayId}`,
        values
      )
      .then((response) => {
        getMinimumStaysData();
        onCloseModal();
        // Optionally, you can update the datatable state to reflect the updated data without the deleted blog.
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getMinimumStaysData();
  }, []);

  return (
    <>
      <AdminSidebar>
        <div className="form add-form">
          <Formik
            initialValues={formState}
            onSubmit={(values, { resetForm }) => {
              addMinimumStayHandler(values);
              onCloseModal();
              resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="stay-form">
                  <Container>
                    <div className="form-field">
                      <div>
                        <label for="start_date" class="col-form-label">
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control form-select"
                          id="start_date"
                          name="start_date"
                          value={values.start_date}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label for="end_date" class="col-form-label">
                          End Date
                        </label>
                        <input
                          type="date"
                          className="form-control form-select"
                          id="end_date"
                          name="end_date"
                          value={values.end_date}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-dropdown">
                        <label for="selected_days" className="col-form-label">
                          Select Days
                        </label>
                        <select
                          id="selected_days"
                          className="form-control form-select"
                          value={values.selected_days}
                          onChange={handleChange}
                          name="selected_days"
                        >
                          <option value="">Select an option</option>
                          <option value="1"> 1</option>
                          <option value="2"> 2</option>
                          <option value="3"> 3</option>
                          <option value="4"> 4</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-text">Unit Type:</div>
                    <div className="form-field">
                      <div>
                        <label for="category_1" className="col-form-label">
                          1 Bed / 1 bath
                        </label>
                        <input
                          type="number"
                          className="form-control form-select"
                          id="category_1"
                          name="category_1"
                          value={values.category_1}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label for="category_2" className="col-form-label">
                          2 Bed / 2 bath
                        </label>
                        <input
                          type="number"
                          className="form-control form-select"
                          id="category_2"
                          name="category_2"
                          value={values.category_2}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-dropdown">
                        <label for="category_3" className="col-form-label">
                          3 Bed / 3 bath
                        </label>
                        <input
                          type="number"
                          className="form-control form-select"
                          id="category_3"
                          name="category_3"
                          value={values.category_3}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-field">
                      <div>
                        <label for="category_4" class="col-form-label">
                          Deluxe 1 Bed / 2 Bath / Home Office:
                        </label>
                        <input
                          type="number"
                          className="form-control form-select"
                          id="category_4"
                          name="category_4"
                          value={values.category_4}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label for="category_5" class="col-form-label">
                          Deluxe 2 Bed / 3 Bath / Home Office:
                        </label>
                        <input
                          type="number"
                          className="form-control form-select"
                          id="category_5"
                          name="category_5"
                          value={values.category_5}
                          onChange={handleChange}
                        />
                      </div>
                      <span>
                        <button className="addStayBtn" type="submit">
                          Add Stay
                        </button>
                      </span>
                    </div>
                  </Container>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <Card>
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={datatable}
          />
        </Card>
        <Modal open={open} onClose={onCloseModal} center>
          <Formik
            initialValues={myStayData}
            onSubmit={(values, { resetForm }) => {
              editHandler(values);
              onCloseModal();
              resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="stay-form">
                  <Container>
                    <div className="edit-form-field">
                      <div>
                        <label for="start_date" class="col-form-label">
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control form-select"
                          id="start_date"
                          name="start_date"
                          value={values.start_date}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label for="end_date" class="col-form-label">
                          End Date
                        </label>
                        <input
                          type="date"
                          className="form-control form-select"
                          id="end_date"
                          name="end_date"
                          value={values.end_date}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-dropdown">
                        <label for="selected_days" className="col-form-label">
                          Select Days
                        </label>
                        <select
                          id="selected_days"
                          className="form-control form-select"
                          value={values.selected_days}
                          onChange={handleChange}
                          name="selected_days"
                        >
                          <option value="">Select an option</option>
                          <option value="1"> 1</option>
                          <option value="2"> 2</option>
                          <option value="3"> 3</option>
                          <option value="4"> 4</option>
                        </select>
                      </div>
                      <div className="form-text">Unit Type:</div>
                      <div>
                        <label for="category_1" className="col-form-label">
                          1 Bed / 1 bath
                        </label>
                        <input
                          type="number"
                          className="form-control form-select"
                          id="category_1"
                          name="category_1"
                          value={values.category_1}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label for="category_2" className="col-form-label">
                          2 Bed / 2 bath
                        </label>
                        <input
                          type="number"
                          className="form-control form-select"
                          id="category_2"
                          name="category_2"
                          value={values.category_2}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-dropdown">
                        <label for="category_3" className="col-form-label">
                          3 Bed / 3 bath
                        </label>
                        <input
                          type="number"
                          className="form-control form-select"
                          id="category_3"
                          name="category_3"
                          value={values.category_3}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label for="category_4" class="col-form-label">
                          Deluxe 1 Bed / 2 Bath / Home Office:
                        </label>
                        <input
                          type="number"
                          className="form-control form-select"
                          id="category_4"
                          name="category_4"
                          value={values.category_4}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label for="category_5" class="col-form-label">
                          Deluxe 2 Bed / 3 Bath / Home Office:
                        </label>
                        <input
                          type="number"
                          className="form-control form-select"
                          id="category_5"
                          name="category_5"
                          value={values.category_5}
                          onChange={handleChange}
                        />
                      </div>
                      <button type="submit" className="addStayBtn">
                        Save
                      </button>
                    </div>
                  </Container>
                </div>
              </form>
            )}
          </Formik>
        </Modal>
      </AdminSidebar>
    </>
  );
}
