export const columnsDataColumns = [
 
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "ACTION",
  },
];

