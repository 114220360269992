import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Textarea,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import React, { useMemo, useEffect, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";
// Custom components
import Card from "components/card/Card";
import AdminSidebar from "layouts/admin";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

export default function AmenitiesTable(props) {
  const { columnsData, tableData } = props;
  const cancelRef = React.useRef();
  const columns = useMemo(() => columnsData, [columnsData]);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const data = useMemo(() => amenitiesList, [amenitiesList]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editData, setEditData] = useState({
    title: "",
    description: "",
  });
  const [addData, setAddData] = useState({
    title: "",
    description: "",
  });
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const closeAddModal = () => {
    setAddData({ title: "", description: "" });
    setIsAddModalOpen(false);
  };

  useEffect(() => {
    getAmenitiesData();
  }, []);
  const getAmenitiesData = () => {
    axios
      .get("${process.env.REACT_APP_BASE_URL}/amenities")
      .then((data) => {
        setAmenitiesList(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleEditClick = (obj) => {
    setIsModalOpen(true);
    setEditData({
      ...obj,
    });
  };

  const handleDeleteClick = (id) => {
    setDeleteOpen(true);
    setDeleteId(id);
  };
  const onDeleteClose = () => {
    setDeleteOpen(false);
    setDeleteId(null);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;
  const onChange = (key, value) => {
    setEditData({ ...editData, [key]: value });
  };

  const handleChange = (key, value) => {
    setAddData({ ...addData, [key]: value });
  };

  const updateAmenities = () => {
    setIsModalOpen(false);
    axios
      .patch("${process.env.REACT_APP_BASE_URL}/amenities/" + editData.id, editData)
      .then((data) => {
        getAmenitiesData();
        setEditData({ title: "", description: "" });
      })
      .catch((err) => {});
  };

  const onDelete = () => {
    axios
      .delete("${process.env.REACT_APP_BASE_URL}/amenities/" + deleteId)
      .then((data) => {
        getAmenitiesData();
        onDeleteClose();
      })
      .catch((err) => {
        onDeleteClose();
      });
  };

  const addAmenities = () => {
    setIsAddModalOpen(false);
    axios
      .post("${process.env.REACT_APP_BASE_URL}/amenities", addData)
      .then((data) => {
        getAmenitiesData();
        setAddData({ title: "", description: "" });
      })
      .catch((err) => {});
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  return localStorage.getItem("token") === "aosijdhfqh4982h342938fhw398we9rfhw9e8rhc9werf9wuhdoiadifuahsdifidufhwer89h39ufhw93r8uhf" ? (
    <AdminSidebar>
      {loading ? (
        <Flex justify="center">
          <Spinner size="lg" color="gray.500" thickness="4px" />
        </Flex>
      ) : (
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                      style={{
                        width: column.Header === "ACTION" ? "150px" : "auto",
                      }}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        {column.Header === "ACTION" && (
                          <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={() => setIsAddModalOpen(true)}
                            style={{
                              margin: " 0px",
                              display: "flex",
                              width: "50px",
                              height: "25px",
                              fontSize: "12px",
                              padding: "5px",
                              lineHeight: "19px",
                            }}
                          >
                            Add
                          </Button>
                        )}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "Title") {
                        data = (
                          <Flex align="center">
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.row.original.title}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header === "Description") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.row.original.description}
                          </Text>
                        );
                      } else if (cell.column.Header === "ACTION") {
                        data = (
                          <Flex
                            justify="center"
                            align="center"
                            fontSize={{ sm: "10px", lg: "12px" }}
                            color="gray.400"
                          >
                            <IconButton
                              icon={<EditIcon />}
                              aria-label="Edit"
                              size="sm"
                              colorScheme="blue"
                              mr="2"
                              onClick={() => {
                                handleEditClick(cell.row.original);
                              }}
                            />
                            <IconButton
                              icon={<DeleteIcon />}
                              aria-label="Delete"
                              size="sm"
                              colorScheme="blue"
                              // mr="2"
                              onClick={() => {
                                handleDeleteClick(cell.row.original.id);
                              }}
                            />
                          </Flex>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          {isModalOpen && (
            // Chakra UI modal component with input fields
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Amenitie</ModalHeader>
                <ModalBody>
                  <FormControl>
                    <FormLabel>Title</FormLabel>
                    <Input
                      type="text"
                      value={editData.title}
                      onChange={(e) => {
                        onChange("title", e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl mt="5">
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      type="text"
                      value={editData.description}
                      onChange={(e) => {
                        onChange("description", e.target.value);
                      }}
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={updateAmenities}>
                    Save
                  </Button>
                  <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}

          {isAddModalOpen && (
            // Chakra UI modal component with input fields
            <Modal
              isOpen={isAddModalOpen}
              onClose={() => setIsAddModalOpen(false)}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add Amenitie</ModalHeader>
                <ModalBody>
                  <FormControl>
                    <FormLabel>Title</FormLabel>
                    <Input
                      type="text"
                      value={addData.title}
                      onChange={(e) => {
                        handleChange("title", e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl mt="5">
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      type="text"
                      value={addData.description}
                      onChange={(e) => {
                        handleChange("description", e.target.value);
                      }}
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={addAmenities}>
                    Save
                  </Button>
                  <Button onClick={() => closeAddModal()}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}

          <AlertDialog
            isOpen={isDeleteOpen}
            leastDestructiveRef={cancelRef}
            onClose={onDeleteClose}
          >
            <AlertDialogOverlay />
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Item
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete this item?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onDeleteClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={onDelete} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </Card>
      )}
    </AdminSidebar>
  ) : (
    <Redirect to="/admin" />
  );
}
