import React, { useMemo, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";
// Custom components
import Card from "components/card/Card";
import TableHeader from "components/tableHeader";
import AdminSidebar from "layouts/admin";
import { MDBDataTableV5 } from "mdbreact";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IconButton } from "@chakra-ui/react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Swal from "sweetalert2";

export default function Property({}) {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [Editopen, setEditOpen] = useState(false);
  const onEditOpenModal = () => setEditOpen(true);
  const onEditCloseModal = () => setEditOpen(false);

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "id",
        field: "id",
        width: 270,
      },
      {
        label: "Property Name",
        field: "name",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Description",
        field: "position",
        width: 270,
      },
      {
        label: "Price",
        field: "price",
        width: 100,
      },
      {
        label: "Square foot",
        field: "square_foot",
        width: 100,
      },
      {
        label: "Num washroom",
        field: "num_washroom",
        width: 100,
      },
      {
        label: "Room Capacity",
        field: "room_capacity",
        width: 100,
      },
      {
        label: "Image",
        field: "image",
        width: 200,
      },
      {
        label: "Action",
        field: "actions",
        width: 100,
      },

      {
        label: (
          <button onClick={onOpenModal} className="btn btn-primary">
            Add
          </button>
        ),
        field: "button",
        width: 100,
      },
    ],
    rows: [
      {
        name: "Tiger Nixon",
        position: "System Architect",
        office: "Edinburgh",
        age: "61",
        date: "2011/04/25",
        salary: "$320",
      },
    ],
  });

  const [fileName, setFileName] = useState("");
  const [base64, setBase64] = useState([]);
  const [currentBlogId, setCurrentBlogId] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleUpload = async (event) => {
    try {
      // Create FormData object
      const formData = new FormData();
      formData.append("image", event.target.files[0]);

      // Make API call using Axios
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/uploadImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the API response
      console.log("API Response:", response.data);
      setSelectedFile(response.data.imageUrl);
    } catch (error) {
      // Handle errors
      console.error("Error uploading image:", error);
    }
  };

  const getGalleryData = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/property`).then((response) => {
      const data = response.data.map((item) => {
        // Convert buffer array to base64 string
        // const base64Image = btoa(
        //   new Uint8Array(item.image.data).reduce(
        //     (binary, byte) => binary + String.fromCharCode(byte),
        //     ""
        //   )
        // );
        // console.log(base64Image, "base64Image property");

        // const cleanedBase64Image = base64Image.split("base64")[1];
        // let url = "";
        // if (base64Image.includes('image/png;base64')) {
        //   url = `data:image/png;base64,${cleanedBase64Image}`
        // }else{
        //   url = `data:image/jpeg;base64,${cleanedBase64Image}`

        // }
        // console.log(url,'url');
        // console.log(base64Image,'base64Image');

        // console.log(base64Image.includes("/jpegbase64"));
        return {
          id: item.id,
          name: item.title,
          position: item.description,
          price: item.price,
          square_foot: item.square_foot,
          num_washroom: item.num_washroom,
          room_capacity: item.room_capacity,

          image: (
            <img
              src={
                item.image
                // base64Image.includes("/jpegbase64")
                //   ? `data:image/jpeg;base64,${cleanedBase64Image}`
                //   : `data:image/png;base64,${cleanedBase64Image}`
              }
              alt="hello"
              style={{ maxHeight: "5rem", maxWidth: "70%" }}
            />
          ),
          actions: (
            <div>
              <button className="mx-3" onClick={() => handleEdit(item.id)}>
                <IconButton
                  icon={<EditIcon />}
                  aria-label="Edit"
                  size="sm"
                  colorScheme="blue"
                  mr="2"
                />
              </button>
              <button onClick={() => handleDelete(item.id)}>
                <IconButton
                  icon={<DeleteIcon />}
                  aria-label="Delete"
                  size="sm"
                  colorScheme="blue"
                  mr="2"
                />
              </button>
            </div>
          ),
        };
      });

      setDatatable((prevState) => ({
        ...prevState,
        rows: data,
      }));
    });
  };

  const handleEdit = (id) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/property/${id}`)
      .then((response) => {
        const blogData = response.data;
        // Pre-fill the form with retrieved blog data
        // const base64Image = btoa(
        //   new Uint8Array(blogData.image.data).reduce(
        //     (binary, byte) => binary + String.fromCharCode(byte),
        //     ""
        //   )
        // );
        // console.log(base64Image, "base64Image property");
        // const cleanedBase64Image = base64Image.split("base64")[1];

        setMyBlogData({
          title: blogData.title,
          description: blogData.description,
          price: blogData.price,
          square_foot: blogData.square_foot,
          room_capacity: blogData.room_capacity,
          num_washroom: blogData.num_washroom,
          image: blogData.image,
        });

        setCurrentBlogId(id);
        setEditOpen(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEditSubmit = (values) => {
    axios
      .patch(`${process.env.REACT_APP_BASE_URL}/property/${currentBlogId}`, {
        ...values,
        image: selectedFile,
      })
      .then((response) => {
        console.log(response.data);
        // Close the modal and reset the form
        onEditCloseModal();

        // Optionally, you can update the datatable state to reflect the updated data
        getGalleryData();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/property/${id}`)
          .then((response) => {
            getGalleryData();
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            console.log(`Blog with ID ${id} deleted successfully.`);
            console.log(response.data.message);
            // Optionally, you can update the datatable state to reflect the updated data without the deleted blog.
          })
          .catch((error) => {
            Swal.fire(
              "Error",
              "An error occurred while deleting the file.",
              "error"
            );
            console.error(`Error deleting blog with ID ${id}:`, error);
          });
      }
    });
  };

  // console.log(selectedFile,'selectedFile');

  const AddBlog = (values) => {
    console.log(values, "values");
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/property`, {
        ...values,
        image: selectedFile,
      })
      .then((response) => {
        getGalleryData();
        onCloseModal();
        console.log(values, "after values ");
        // Optionally, you can update the datatable state to reflect the updated data without the deleted blog.
      })

      .catch((error) => {
        console.error(error);
      });
  };

  const handleFileChange = async (event, setFieldValue) => {
    // setFieldValue("fileName", event.target.value.split("\\")[2]);

    const file = event.target.files[0];

    const base64String = await convertFileToBase64(file);
    console.log(base64String, "funtion");
    setFieldValue("image", base64String);
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    getGalleryData();
  }, []);

  const [myBlogData, setMyBlogData] = useState({
    title: "",
    price: "",
    description: "",
    square_foot: "",
    room_capacity: "",
    num_washroom: "",
    // image: null,

    // notes: "",
  });

  return (
    <>
      <AdminSidebar>
        <Card>
          <Modal open={open} onClose={onCloseModal} center>
            <Formik
              initialValues={myBlogData}
              onSubmit={(values, { resetForm }) => {
                AddBlog(values);
                onCloseModal();
                resetForm();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div class="form-group">
                    <label for="title" class="col-form-label">
                      Property Name:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="title"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label for="description" class="col-form-label">
                      Description:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label for="description" class="col-form-label">
                      Price:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="price"
                      name="price"
                      value={values.price}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label for="description" class="col-form-label">
                      Room Capicity:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="room_capacity"
                      name="room_capacity"
                      value={values.room_capacity}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label for="description" class="col-form-label">
                      Square Foot:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="square_foot"
                      name="square_foot"
                      value={values.square_foot}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label for="description" class="col-form-label">
                      Number Washroom:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="num_washroom"
                      name="num_washroom"
                      value={values.num_washroom}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label
                      for="recipient-name"
                      class="col-form-label"
                      // onChange={handleChange}
                    >
                      Select Image:
                    </label>
                    <input
                      type="file"
                      class="form-control"
                      id="image"
                      name="image"
                      // onChange={(e) => handleFileChange(e, setFieldValue)}
                      onChange={handleUpload}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </form>
              )}
            </Formik>
          </Modal>
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={datatable}
          />
        </Card>
        <Modal open={Editopen} onClose={onEditCloseModal} center>
          <Formik
            initialValues={myBlogData}
            onSubmit={(values, { resetForm }) => {
              handleEditSubmit(values);
              onEditCloseModal();
              resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div class="form-group">
                  <label for="title" class="col-form-label">
                    Property Name:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="title"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                  />
                </div>
                <div class="form-group">
                  <label for="description" class="col-form-label">
                    Description:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  />
                </div>
                <div class="form-group">
                  <label for="description" class="col-form-label">
                    Price:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="price"
                    name="price"
                    value={values.price}
                    onChange={handleChange}
                  />
                </div>
                <div class="form-group">
                  <label for="description" class="col-form-label">
                    Room Capicity:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="room_capacity"
                    name="room_capacity"
                    value={values.room_capacity}
                    onChange={handleChange}
                  />
                </div>
                <div class="form-group">
                  <label for="description" class="col-form-label">
                    Square Foot:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="square_foot"
                    name="square_foot"
                    value={values.square_foot}
                    onChange={handleChange}
                  />
                </div>
                <div class="form-group">
                  <label for="description" class="col-form-label">
                    Number Washroom:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="num_washroom"
                    name="num_washroom"
                    value={values.num_washroom}
                    onChange={handleChange}
                  />
                </div>
                <div class="form-group">
                  <label
                    for="recipient-name"
                    class="col-form-label"
                    onChange={handleChange}
                  >
                    Select Image:
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="image"
                    name="image"
                    onChange={(e) => handleFileChange(e, setFieldValue)}
                    // onChange={handleUpload}
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </form>
            )}
          </Formik>
        </Modal>
      </AdminSidebar>
    </>
  );
}
