import Footer from "../common/footer";
import Header from "../common/header";
import LeftBar from "../common/leftbar";
import "../common/css/jquery-ui.css";
import "../common/css/fontawesome-all.min.css";
import "../common/css/fontawesome-5-all.min.css";
import "../common/css/font-awesome.min.css";
import "../common/css/search.css";
import "../common/css/animate.css";
import "../common/css/aos2.css";
import "../common/css/swiper.min.css";
import "../common/css/magnific-popup.css";
import "../common/css/lightcase.css";
import "../common/css/bootstrap.min.css";
import "../common/css/menu.css";
import "../common/css/default.css";
import "../common/css/styles.css";

const Contact = () => {
  return (
    <div className="inner-pages int_white_bg">
      <LeftBar />
      <Header />

      <section className="headings">
        <div className="text-heading text-center">
          <div className="container">
            <h1>Contact Us</h1>
            <h2>
              <a href="index.html">Home </a> &nbsp;/&nbsp; Contact
            </h2>
          </div>
        </div>
      </section>
      {/* <!-- END SECTION HEADINGS --> */}

      <section
        className="services-home rec-pro"
        style={{ paddingBottom: "10% !important" }}
      >
        <div className="container" style={{ maxWidth: "85%" }}>
          <div className="property-location mb-5">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <h3 className="mb-4">Contact Us</h3>
                <form
                  id="contactform"
                  className="contact-form"
                  name="contactform"
                  method="post"
                  novalidate
                >
                  <div id="success" className="successform">
                    <p
                      className="alert alert-success font-weight-bold"
                      role="alert"
                    >
                      Your message was sent successfully!
                    </p>
                  </div>
                  <div id="error" className="errorform">
                    <p>
                      Something went wrong, try refreshing and submitting the
                      form again.
                    </p>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          required
                          className="form-control input-custom input-full"
                          name="name"
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          required
                          className="form-control input-custom input-full"
                          name="name"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <input
                      type="number"
                      required
                      className="form-control input-custom input-full"
                      name="number"
                      placeholder="Mobile Number"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control input-custom input-full"
                      name="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control textarea-custom input-full"
                      id="ccomment"
                      name="message"
                      required
                      rows="8"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    id="submit-contact"
                    className="btn btn-primary btn-lg"
                  >
                    Submit
                  </button>
                </form>
              </div>
              <div className="col-lg-4 col-md-12 bgc">
                <div className="call-info">
                  <h3>Contact Details</h3>
                  <p className="mb-5">
                    Please find below contact details and contact us today!
                  </p>
                  <ul>
                    <li>
                      <div className="info">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                        <p className="in-p">
                          128 Grand Ave, Johnson City, NY 13790
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="info">
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        <p className="in-p">(347) 915-3560</p>
                      </div>
                    </li>
                    <li>
                      <div className="info">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                        <p className="in-p ti">info@loftsatjc.com</p>
                      </div>
                    </li>
                    <li>
                      <div className="info cll">
                        <i className="fa fa-clock-o" aria-hidden="true"></i>
                        <p className="in-p ti">8:00 a.m - 9:00 p.m</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="property-location mb-5">
            <h3>Our Location</h3>
            <div className="divider-fade"></div>
            {/* <!-- <div id="map-contact" className="contact-map"></div> --> */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5919.755776118043!2d-75.953382!3d42.110086!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89daeef969ab6ffb%3A0x11082d4814a2309e!2s128%20Grand%20Ave%2C%20Johnson%20City%2C%20NY%2013790%2C%20USA!5e0!3m2!1sen!2sin!4v1680074142522!5m2!1sen!2sin"
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
