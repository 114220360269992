import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const ChangePassword = () => {
  const [current, setCurrent] = useState("");
  const [newpwd, setNew] = useState("");
  const [confirm, setConfirm] = useState("");
  const [errMsg, setMsg] = useState("");

  const clearData = () => {
    setCurrent("");
    setNew("");
    setConfirm("");
  };

  const updatePassword = () => {
    const token = localStorage.getItem("token");
    axios
      .post(
        "${process.env.REACT_APP_BASE_URL}/change-password",
        {
          old_password: current,
          new_password: newpwd,
          confirm_new_password: confirm,
        },
        {
          headers: {
            authorization: token, // Set the token in the Authorization header
          },
        }
      )
      .then((data) => {
        // setUserData(data.data);
        // setLoading(false);
        clearData();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Your password has been updated succesfully',
          showConfirmButton: false,
          timer: 2500
        });
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          setMsg(err.response.data.message);
        }
      });
  };

  return (
    <section>
      <br />
      <div className="my-properties my-address" style={{ padding: "5%" }}>
        <form>
          <div className="row">
            <div className="col-lg-12 ">
              <div className="form-group name">
                <label>Current Password</label>
                <input
                  type="password"
                  name="current-password"
                  className="form-control"
                  placeholder="Current Password"
                  value={current}
                  onChange={(e) => setCurrent(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group email">
                <label>New Password</label>
                <input
                  type="password"
                  name="new-password"
                  className="form-control"
                  placeholder="New Password"
                  value={newpwd}
                  onChange={(e) => setNew(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-12 ">
              <div className="form-group subject">
                <label>Confirm New Password</label>
                <input
                  type="password"
                  name="confirm-new-password"
                  className="form-control"
                  placeholder="Confirm New Password"
                  value={confirm}
                  onChange={(e) => setConfirm(e.target.value)}
                />
              </div>
            </div>
            {errMsg}
            <div className="col-lg-12">
              <div className="send-btn mt-2">
                <button
                  type="button"
                  className="btn btn-common"
                  onClick={() => {
                    if (newpwd !== confirm) {
                      setMsg("New and Confirm Password does not match!");
                    } else if (!newpwd || !confirm || !current) {
                      setMsg("Please fill all fields");
                    } else {
                      setMsg("");
                      updatePassword();
                    }
                  }}
                >
                  Send Changes
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ChangePassword;
