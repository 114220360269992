export const columnsDataColumns = [
  {
    Header: "Category",
    accessor: "name",
  },
  {
    Header: "ACTION",
  },
];

