import React, { useEffect, useState } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const Bulding = () => {
  const [myData, setMyData] = useState({
    start_date: "",
    end_date: "",
    bathrooms: "1",
    beds: "1",
  });

  const history = useHistory();

  const [error, setIsError] = useState("");
  const [flatData, setFlatData] = useState({
    floor1: true,
    floor2: true,
    floor3: true,
  });

  const getAvailableBulding = async (values) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/check-available-floors`,
        values
      );
      setFlatData(res.data.data);
      localStorage.setItem("start_date" , values.start_date);
      localStorage.setItem("END_date" , values.end_date);

      if (res.code === 401) {
        setIsError(res.message);
      }
    } catch (error) {
      setIsError(error.message);
      console.log(error);
    }
  };

  const floorOnclick = (values, floorNo) => {
    console.log(floorNo);
    if (values.start_date && values.end_date) {
      history.push("/buildingDetails", { floorNo, values });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Select Start Date!",
        // footer: '<a href="">Why do I have this issue?</a>',
      });
    }
  };

  return (
    <div className="main">
      <div className="row">
        <Formik
          initialValues={myData}
          // validationSchema={SigninSchema}
          onSubmit={(values) => {
            getAvailableBulding(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <>
              <div className="col-md-3">
                <div className="filter-1">
                  <div class="form-group">
                    <label for="title" class="col-form-label">
                      start Date:
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      id="title"
                      name="start_date"
                      value={values.start_date}
                      onChange={handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label for="title" class="col-form-label">
                      End Date:
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      id="title"
                      name="end_date"
                      value={values.end_date}
                      onChange={handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label for="title" class="col-form-label">
                      Select Bathroom:
                    </label>
                    <div className="dropdown ">
                      <select
                        name="bathrooms"
                        value={values.bathrooms}
                        onChange={handleChange}
                      >
                        <option value="1"> 1</option>
                        <option value="2"> 2</option>
                        <option value="3"> 3</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="title" class="col-form-label">
                      Select Beds:
                    </label>
                    <div className="dropdown ">
                      <select
                        name="beds"
                        value={values.beds}
                        onChange={handleChange}
                      >
                        <option value="1"> 1</option>
                        <option value="2"> 2</option>
                        <option value="3"> 3</option>
                      </select>
                    </div>
                  </div>

                  {/* {console.log(error,'eroore')} */}
                  <div className="text-danger">{error}</div>

                  <button className="btn btn-primary" onClick={handleSubmit}>
                    Apply Filter
                  </button>
                </div>
              </div>
              <div className="col-md-9">
                <div className="sky-img">
                  <img src="/images/sky1.png" alt="sky" />
                </div>
                <div
                  className="floor-1 hover"
                  onClick={() => floorOnclick(values, 1)}
                >
                  <img src="/images/floor-1.png" alt="floor" />
                  {flatData?.floor1 && <div className="overlay"></div>}
                </div>
                <div
                  className="floor-2 hover"
                  onClick={() => floorOnclick(values, 2)}
                >
                  <img src="/images/floor-2.png" alt="floor" />
                  {flatData?.floor2 && <div className="overlay"></div>}
                </div>
                <div
                  className="floor-3 hover"
                  onClick={() => floorOnclick(values, 3)}
                >
                  <img src="/images/floor-3.png" alt="floor" />
                  {flatData?.floor3 && <div className="overlay"></div>}
                </div>
                <div className="footer-img">
                  <img src="/images/floor1.png" alt="floor" />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Bulding;
