import React, { useState } from "react";
import fp1 from "../common/images/feature-properties/fp-1.jpg";
import fp2 from "../common/images/feature-properties/fp-2.jpg";

const Wishlist = () => {
  return       <section>
                        <div className="my-properties">
                            <table className="table-responsive">
                                <thead>
                                    <tr>
                                        <th className="pl-2">Top Property</th>
                                        <th className="p-0"></th>
                                        <th>Date Added</th>
                                        <th>Views</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="image myelist">
                                            <a href="single-property-1.html"><img alt="my-properties-3" src={fp1} className="img-fluid"/></a>
                                        </td>
                                        <td>
                                            <div className="inner">
                                                <a href="single-property-1.html"><h2>Luxury Villa House</h2></a>
                                                <figure><i className="lni-map-marker"></i> Est St, 77 - Central Park South, NYC</figure>
                                                <ul className="starts text-left mb-0">
                                                    <li className="mb-0"><i className="fa fa-star"></i>
                                                    </li>
                                                    <li className="mb-0"><i className="fa fa-star"></i>
                                                    </li>
                                                    <li className="mb-0"><i className="fa fa-star"></i>
                                                    </li>
                                                    <li className="mb-0"><i className="fa fa-star"></i>
                                                    </li>
                                                    <li className="mb-0"><i className="fa fa-star"></i>
                                                    </li>
                                                    <li className="ml-3">(6 Reviews)</li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td>08.14.2020</td>
                                        <td>163</td>
                                        <td className="actions">
                                            <a href="#" className="edit"><i className="fa fa-heart" aria-hidden="true"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="image">
                                            <a href="single-property-1.html"><img alt="my-properties-3" src={fp2} className="img-fluid" /></a>
                                        </td>
                                        <td>
                                            <div className="inner">
                                                <a href="single-property-1.html"><h2>Luxury Villa House</h2></a>
                                                <figure><i className="lni-map-marker"></i> Est St, 77 - Central Park South, NYC</figure>
                                                <ul className="starts text-left mb-0">
                                                    <li className="mb-0"><i className="fa fa-star"></i>
                                                    </li>
                                                    <li className="mb-0"><i className="fa fa-star"></i>
                                                    </li>
                                                    <li className="mb-0"><i className="fa fa-star"></i>
                                                    </li>
                                                    <li className="mb-0"><i className="fa fa-star"></i>
                                                    </li>
                                                    <li className="mb-0"><i className="fa fa-star-o"></i>
                                                    </li>
                                                    <li className="ml-3">(6 Reviews)</li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td>08.14.2020</td>
                                        <td>202</td>
                                        <td className="actions">
                                            <a href="#" className="edit"><i className="fa fa-heart" aria-hidden="true"></i></a>
                                        </td>
                                    </tr>
                                  
                                </tbody>
                            </table>
                            <div className="pagination-container">
                                <nav>
                                    <ul className="pagination">
                                        <li className="page-item"><a className="btn btn-common" href="#"><i className="lni-chevron-left"></i> Previous </a></li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item"><a className="btn btn-common" href="#">Next <i className="lni-chevron-right"></i></a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </section>
    }

export default Wishlist;