import React, { useMemo, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";
// Custom components
import Card from "components/card/Card";
import TableHeader from "components/tableHeader";
import AdminSidebar from "layouts/admin";
import { MDBDataTableV5 } from "mdbreact";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IconButton } from "@chakra-ui/react";
import "react-responsive-modal/styles.css";
import Swal from "sweetalert2";

export default function Complaints({}) {
  // const [datatable, setDatatable] = useState({
  //     columns: [
  //       {
  //         label: "id",
  //         field: "id",
  //         width: 270,
  //       },
  //       {
  //         label: "Name",
  //         field: "name",
  //         width: 150,
  //         attributes: {
  //           "aria-controls": "DataTable",
  //           "aria-label": "Name",
  //         },
  //       },
  //       {
  //         label: "Mobile Number",
  //         field: "mobile_number",
  //         width: 270,
  //       },
  //       {
  //         label: "Unit Number",
  //         field: "unit_number",
  //         width: 200,
  //       },
  //       {
  //         label: "subject",
  //         field: "subject",
  //         width: 200,
  //       },
  //       {
  //         label: "Message",
  //         field: "message",
  //         width: 200,
  //       },
  //       {
  //         label: "Action",
  //         field: "actions",
  //         width: 100,
  //       },
  //     ],
  //     rows: [],
  //   });

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "id",
        field: "id",
        width: 270,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Subject",
        field: "position",
        width: 270,
      },
      {
        label: "Unit Number",
        field: "unit_number",
        width: 270,
      },
      {
        label: "Mobile Number",
        field: "mobile_number",
        width: 270,
      },
      {
        label: "Action",
        field: "actions",
        width: 100,
      },
    ],
    rows: [
      {
        name: "Tiger Nixon",
        position: "System Architect",
        office: "Edinburgh",
        age: "61",
        date: "2011/04/25",
        salary: "$320",
      },
    ],
  });

  const getGalleryData = () => {
    // axios.get("${process.env.REACT_APP_BASE_URL}/complaints").then((response) => {
    //   const data = response.data.map((item) => {
    //     console.log(item,'item');
    //     return {
    //       id: item.id,
    //       name: item.name,
    //       mobile_number: item.mobile_number,
    //       unit_number: item.unit_number,
    //       subject: item.subject,
    //       message: item.message,

    //       actions: (
    //         <div>
    //           <button onClick={() => handleDelete(item.id)}>
    //             <IconButton
    //               icon={<DeleteIcon />}
    //               aria-label="Delete"
    //               size="sm"
    //               colorScheme="blue"
    //               mr="2"
    //             />
    //           </button>
    //         </div>
    //       ),
    //     };
    //   });
    axios.get(`${process.env.REACT_APP_BASE_URL}/complaints`).then((response) => {
      const data = response.data.map((item) => {
        // Convert buffer array to base64 string
        console.log(item, "item");

        return {
          id: item.id,
          name: item.name,
          position: item.subject,
          unit_number: item.unit_number,
          mobile_number: item.mobile_number,

          //       mobile_number: item.mobile_number,
          //       unit_number: item.unit_number,
          //       subject: item.subject,

          actions: (
            <div>
              <button onClick={() => handleDelete(item.id)}>
                <IconButton
                  icon={<DeleteIcon />}
                  aria-label="Delete"
                  size="sm"
                  colorScheme="blue"
                  mr="2"
                />
              </button>
            </div>
          ),
        };
      });

      setDatatable((prevState) => ({
        ...prevState,
        rows: data,
      }));
    });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/complaints/${id}`)
          .then((response) => {
            getGalleryData();
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            console.log(`Blog with ID ${id} deleted successfully.`);
            console.log(response.data.message);
            // Optionally, you can update the datatable state to reflect the updated data without the deleted blog.
          })
          .catch((error) => {
            Swal.fire(
              "Error",
              "An error occurred while deleting the file.",
              "error"
            );
            console.error(`Error deleting blog with ID ${id}:`, error);
          });
      }
    });
  };

  useEffect(() => {
    getGalleryData();
  }, []);

  return (
    <>
      <AdminSidebar>
        <Card>
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={datatable}
          />
        </Card>
      </AdminSidebar>
    </>
  );
}
