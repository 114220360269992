import React, { useMemo, useEffect, useState } from "react";
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Select,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";
// Custom components
import Card from "components/card/Card";
import TableHeader from "components/tableHeader";
import AdminSidebar from "layouts/admin";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
export default function ColumnsTable(props) {
  const { columnsData, tableData } = props;
  const cancelRef = React.useRef();

  const columns = useMemo(() => columnsData, [columnsData]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [galleryCategoryList, setGalleryCategoryList] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addData, setAddData] = useState({
    name: "",
  });
  const data = useMemo(() => galleryCategoryList, [galleryCategoryList]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  useEffect(() => {
    getGalleryCategoryData();
  }, []);

  const getGalleryCategoryData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/gallery_category`)
      .then((data) => {
        setGalleryCategoryList(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleEditClick = (obj) => {
    setIsModalOpen(true);
    setEditData({
      ...obj,
    });
  };

  const handleDeleteClick = (id) => {
    setDeleteOpen(true);
    setDeleteId(id);
  };
  const onDeleteClose = () => {
    setDeleteOpen(false);
    setDeleteId(null);
  };

  const onChange = (key, value) => {
    setEditData({ ...editData, [key]: value });
  };
  const updateGalleryCategory = () => {
    setIsModalOpen(false);
    axios
      .patch(`${process.env.REACT_APP_BASE_URL}/gallery_category/` + editData.id, {
        ...editData,
      })
      .then((data) => {
        getGalleryCategoryData();
      })
      .catch((err) => {});
  };

  const onDelete = () => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/gallery_category/` + deleteId)
      .then((data) => {
        getGalleryCategoryData();
        onDeleteClose();
      })
      .catch((err) => {
        onDeleteClose();
      });
  };

  const closeAddModal = () => {
    setAddData({ name: "" });
    setIsAddModalOpen(false);
  };

  const addCatgory = () => {
    setIsAddModalOpen(false);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/gallery_category`, addData)
      .then((data) => {
        getGalleryCategoryData();
        setAddData({ name: "" });
      })
      .catch((err) => {});
  };

  const handleChange = (key, value) => {
    setAddData({ ...addData, [key]: value });
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return localStorage.getItem("token") === "aosijdhfqh4982h342938fhw398we9rfhw9e8rhc9werf9wuhdoiadifuahsdifidufhwer89h39ufhw93r8uhf" ? (
    <AdminSidebar>
      {loading ? (
        <Flex justify="center">
          <Spinner size="lg" color="gray.500" thickness="4px" />
        </Flex>
      ) : (
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <TableHeader
              headerGroups={headerGroups}
              addClick={() => {
                setIsAddModalOpen(true);
              }}
            />
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "NAME") {
                        data = (
                          <Flex align="center">
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.row.original.first_name +
                                (cell.row.original.last_name
                                  ? " " + cell.row.original.last_name
                                  : "")}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header === "Category") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "Image") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value ? (
                              <img
                                src={
                                  // "data:image/gif;base64," +
                                  cell.value
                                }
                                alt="my image"
                                style={{
                                  height: 65,
                                }}
                              />
                            ) : null}
                          </Text>
                        );
                      } else if (cell.column.Header === "ACTION") {
                        data = (
                          <>
                            <IconButton
                              icon={<EditIcon />}
                              aria-label="Edit"
                              size="sm"
                              colorScheme="blue"
                              mr="2"
                              onClick={() => {
                                handleEditClick(cell.row.original);
                              }}
                            />
                            <IconButton
                              icon={<DeleteIcon />}
                              aria-label="Delete"
                              size="sm"
                              colorScheme="blue"
                              mr="2"
                              onClick={() => {
                                handleDeleteClick(cell.row.original.id);
                              }}
                            />
                          </>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {isModalOpen && (
            // Chakra UI modal component with input fields
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Gallery Category</ModalHeader>
                <ModalBody>
                  <FormControl>
                    <FormLabel>Category Name</FormLabel>
                    <Input
                      type="text"
                      value={editData.name}
                      onChange={(e) => {
                        onChange("name", e.target.value);
                      }}
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={updateGalleryCategory}
                  >
                    Update
                  </Button>
                  <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}

          {isAddModalOpen && (
            // Chakra UI modal component with input fields
            <Modal
              isOpen={isAddModalOpen}
              onClose={() => setIsAddModalOpen(false)}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add Gallery Category</ModalHeader>
                <ModalBody>
                  <FormControl>
                    <FormLabel>Category Name</FormLabel>
                    <Input
                      type="text"
                      value={addData.name}
                      onChange={(e) => {
                        handleChange("name", e.target.value);
                      }}
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={addCatgory}>
                    Save
                  </Button>
                  <Button onClick={() => closeAddModal()}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}

          <AlertDialog
            isOpen={isDeleteOpen}
            leastDestructiveRef={cancelRef}
            onClose={onDeleteClose}
          >
            <AlertDialogOverlay />
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Item
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete this item?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onDeleteClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={onDelete} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </Card>
      )}
    </AdminSidebar>
  ) : (
    <Redirect to="/admin" />
  );
}
