import React, { useMemo, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import { EditIcon, DeleteIcon, CloseIcon } from "@chakra-ui/icons";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";
// Custom components
import Card from "components/card/Card";
import TableHeader from "components/tableHeader";
import AdminSidebar from "layouts/admin";
import { MDBDataTableV5 } from "mdbreact";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IconButton } from "@chakra-ui/react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useHistory } from "react-router-dom";

export default function EditFlat({}) {
  const location = useLocation();
  const { id } = location.state;
  const history = useHistory();

  const Newvalue = {
    unitNo: "",
    bathrooms: "",
    beds: "",
    cleaningFees: "",
    description: "",
    startDate: "",
    endDate: "",
    floorId: "",
    guests: "",
    unitType: "",
    images:[]
  };
  const [flatDetails, setflatDetails] = useState(Newvalue);
  const [unitType, setUnitType] = useState([]);

  const initialValues = {
    unitNo: flatDetails.unitNo || "",
    bathrooms: flatDetails.bathrooms || "",
    beds: flatDetails.beds || "",
    cleaningFees: flatDetails.cleaningFees || "",
    description: flatDetails.description || "",
    startDate: moment(flatDetails.startDate).format("YYYY-MM-DD") || "",
    endDate: moment(flatDetails.endDate).format("YYYY-MM-DD") || "",
    floorId: flatDetails.floorId || "",
    guests: flatDetails.guests || "",
    unitType: flatDetails.unitType || "",
    images: flatDetails.images || [],

  };

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues,
    // validationSchema: updateEmployeeValidation,
    onSubmit: (values) => updateFlat(values),
  });

  console.log(values, "values");

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/flat/${id}`
      );

      const jsonData = await response.json();
      console.log(jsonData);
      setflatDetails(jsonData.flatData[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchUnitType = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/unit-type`
      );

      const jsonData = await response.json();
      console.log(jsonData);
      setUnitType(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleUpload = async (event) => {
    try {
      // Create FormData object
      const formData = new FormData();
      formData.append("image", event.target.files[0]);

      // Make API call using Axios
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/uploadImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the API response
      console.log("API Response:", response.data);
      setFieldValue("images", [...values.images, response.data.imageUrl]);
    } catch (error) {
      // Handle errors
      console.error("Error uploading image:", error);
    }
  };

  const updateFlat = async (values) => {
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/flat/${id}`,
        {
          ...values,
          unitType: +values.unitType,
        }
      );

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Flat updated successfully",
        showConfirmButton: false,
        timer: 1500,
      });

      if (
        values.startDate !== moment(flatDetails.startDate).format("YYYY-MM-DD")
      ) {
        const res = axios.post(
          `${process.env.REACT_APP_BASE_URL}/maintenance`,
          {
            flatId: id,
            start_date: values.startDate,
            end_date: values.endDate,
          }
        );
        console.log("inside condition");
      }

      setTimeout(() => {
        if (res.status === 200) {
          history.push("/admin/flat");
        }
      }, 2000);
    } catch (error) {
      console.error("Error fetching data:", error);
      Swal.fire("Error", "An error occurred while updating the file.", "error");
    }
  };

  useEffect(() => {
    fetchData();
    fetchUnitType();
  }, []);

  useEffect(() => {
    setValues({
      ...flatDetails,
      startDate: moment(flatDetails.startDate).format("YYYY-MM-DD") || "",
      endDate: moment(flatDetails.endDate).format("YYYY-MM-DD") || "",
    });
  }, [flatDetails]);

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);

    // Use Promise.all to convert all selected files to objects with a 'data' property
    Promise.all(
      files.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onload = (event) => {
            const arrayBuffer = event.target.result;
            const uint8Array = new Uint8Array(arrayBuffer);

            // Create an object with a 'data' property for the image data
            const imageData = { data: [...uint8Array] };

            resolve(imageData);
          };

          reader.readAsArrayBuffer(file);
        });
      })
    ).then((imageDataArray) => {
      // Append the imageData objects to the 'images' array
      setFieldValue("images", [...values.images, ...imageDataArray]);
    });
  };

  return (
    <>
      <AdminSidebar>
        <Card>
          <div>
            <div className="row">
              {values?.images?.map((image, index) => {
                // const base64Image = btoa(
                //   new Uint8Array(image.data).reduce(
                //     (binary, byte) => binary + String.fromCharCode(byte),
                //     ""
                //   )
                // );

                // Function to handle image removal
                const handleImageRemove = () => {
                  // Create a copy of the images array without the selected image
                  const updatedImages = values.images.filter(
                    (_, i) => i !== index
                  );

                  // Update the form values with the new images array
                  setFieldValue("images", updatedImages);
                };

                return (
                  <div className="col-md-3" style={{ position: "relative" }}>
                    <img
                      key={index} // Make sure to provide a unique key for each image
                      src={image}
                      alt={`Image ${index}`}
                    />
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      style={{ position: "absolute", top: "0", right: "16px" }}
                      onClick={handleImageRemove}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                );
              })}
            </div>

            <div className=" mt-3">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-4" style={{ marginTop: "35px" }}>
                    <div class="form-group">
                      <div className="dropdown ">
                        <select
                          onChange={handleChange}
                          value={values.floorId}
                          name="floorId"
                        >
                          <option> Select Floor</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="form-group">
                      <label for="title" class="col-form-label">
                        Unit No :
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="title"
                        name="unitNo"
                        value={values.unitNo}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-4" style={{ marginTop: "35px" }}>
                    <div class="form-group">
                      <div className="dropdown ">
                        <select
                          onChange={handleChange}
                          value={values.unitType}
                          name="unitType"
                        >
                          <option> Select Unit Type</option>

                          {unitType?.map((unitType) => (
                            <option key={unitType.id} value={unitType.id}>
                              {unitType.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div class="form-group">
                      <label for="description" class="col-form-label">
                        Price:
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="price"
                        name="price"
                        value={values.price}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div class="form-group">
                      <label for="title" class="col-form-label">
                        cleaning Fees:
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="title"
                        name="cleaningFees"
                        value={values.cleaningFees}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="form-group">
                      <label for="description" class="col-form-label">
                        Description:
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label">beds:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="beds"
                        name="beds"
                        value={values.beds}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label">Bathrooms:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="bathrooms"
                        name="bathrooms"
                        value={values.bathrooms}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label">Guests:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="guests"
                        name="guests"
                        value={values.guests}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="form-group">
                      <label for="title" class="col-form-label">
                        start Date:
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        id="title"
                        name="startDate"
                        value={values.startDate}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="form-group">
                      <label for="title" class="col-form-label">
                        End Date:
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        id="title"
                        name="endDate"
                        value={values.endDate}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label">Select Image</label>
                      <input
                        type="file"
                        class="form-control"
                        id="guests"
                        name="images"
                        // onChange={(e) => {
                        //   handleFileInputChange(e);
                        // }}
                        onChange={handleUpload}
                      />
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </form>
            </div>
          </div>
        </Card>
      </AdminSidebar>
    </>
  );
}
