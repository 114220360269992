import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Swal from "sweetalert2";

const BookingForm = () => {
  const navigate = useHistory();
  const startDateString = localStorage.getItem("start_date"); // Assuming "start_date" is the key in your localStorage
  const endDateString = localStorage.getItem("END_date"); // Assuming "END_date" is the key in your localStorage

  // Convert the date strings to Date objects
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  // Calculate the difference in days
  const timeDifference = endDate - startDate;
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  const [error, setIsError] = useState("");
  const location = useLocation();
  const { data } = location.state;
  const [myData, setData] = useState({
    fullName: "",
    mobileNumber: "",
    reason: "",
    emailId: "",
    deposit: "500",
    xCardNum: "",
    xExp: "",
    xCvv: "",
    userId: 1,
    floorNo: data.floorId,
    xAmount: (data?.price + data?.cleaningFees) * daysDifference + 2300,
    idProof: "",
    unitNo: data.id,
    pricePerDay: data?.price,
    startDate: startDateString,
    endDate: endDateString,
  });
  console.log(data, "form data");

  const BookFlat = async (values) => {
    console.log({ ...values, ...data });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/authOnly-payment`,
        values
      );
      if (res.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Flat Booked successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate.push("/");
      }
    } catch (error) {
      setIsError(error.message);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Want Wrong!",
        // footer: '<a href="">Why do I have this issue?</a>',
      });
      console.log(error);
    }
  };

  const handleFileChange = async (event, setFieldValue) => {
    // setFieldValue("fileName", event.target.value.split("\\")[2]);

    const file = event.target.files[0];

    const base64String = await convertFileToBase64(file);
    setFieldValue("idProof", base64String);
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="main">
      <div className="container mt-5">
        <div>
          <Formik
            initialValues={myData}
            onSubmit={(values, { resetForm }) => {
              BookFlat(values);
              //   resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <div className="row">
                <div className="col-md-6">
                  <div class="form-group">
                    <label for="title" class="col-form-label">
                      full Name:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="fullName"
                      name="fullName"
                      value={values.fullName}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div class="form-group">
                    <label for="title" class="col-form-label">
                      Mobile Number:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="mobileNumber"
                      name="mobileNumber"
                      value={values.mobileNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div class="form-group">
                    <label for="title" class="col-form-label">
                      reason:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="reason"
                      name="reason"
                      value={values.reason}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-group">
                    <label for="title" class="col-form-label">
                      email:
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="emailId"
                      name="emailId"
                      value={values.emailId}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label for="title">select idProof:</label>
                    <input
                      type="file"
                      class="form-control"
                      id="idProof"
                      name="idProof"
                      onChange={(e) => handleFileChange(e, setFieldValue)}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="payment-card">
                    <div className="top">
                      <div className="payment">Payment Details</div>
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="one">
                          <label htmlFor>Name on card</label>
                          <input
                            placeholder="Johny Relative"
                            type="text"
                            className="input"
                          />
                        </div>
                        <div className="two">
                          <label htmlFor>Card Number</label>
                          <input
                            maxLength={16}
                            placeholder="4478 6632 9923 8890"
                            type="number"
                            className="input"
                            name="xCardNum"
                            value={values.xCardNum}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="three">
                          <label htmlFor>Expiry Date</label>
                          <input
                            placeholder="01/22"
                            type="number"
                            className="input"
                            name="xExp"
                            value={values.xExp}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="five">
                          <label>CVV</label>
                          <input
                            maxLength={3}
                            placeholder={633}
                            type="number"
                            className="input"
                            name="xCvv"
                            value={values.xCvv}
                            onChange={handleChange}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-md-6"></div>

                <div className="text-center mt-5 mx-3">
                  <button
                    className="btn-primary"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default BookingForm;
