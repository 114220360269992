import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

const FlatDetails = () => {
  const location = useLocation();
  const { data } = location.state;
  const history = useHistory();
  const start = localStorage.getItem("start_date");
  const end = localStorage.getItem("END_date");

  const navigateBook = () => {
    history.push("/bookingForm", { data });
  };

  return (
    <div className="main">
      <div className="container">
        <div className="details mt-5">
          <div className="floorDetails d-flex">
            <p className="floor">Floor No :</p>
            <p className="mx-5 number">{data.floorId}</p>
          </div>
          <div className="floorDetails d-flex mt-3">
            <p className="floor">Unit Type</p>
            <p className="mx-5 number">{data.unitType}</p>
          </div>
          <div className="floorDetails d-flex mt-3">
            <p className="floor">Price</p>
            <p className="mx-5 number">{data.price}</p>
          </div>
          <div className="floorDetails d-flex mt-3">
            <p className="floor">Unit No :</p>
            <p className="mx-5 number">{data.unitNo}</p>
          </div>
          <div className="floorDetails d-flex mt-3">
            <p className="floor">Cleaning Fees :</p>
            <p className="mx-5 number">{data.cleaningFees}</p>
          </div>
          <div className="floorDetails d-flex mt-3">
            <p className="floor">Total Guest :</p>
            <p className="mx-5 number">{data.guests}</p>
          </div>
          <div className="floorDetails d-flex mt-3">
            <p className="floor">Descriotion :</p>
            <p className="mx-5 number">{data.description}</p>
          </div>
          <div className="floorDetails d-flex mt-3">
            <p className="floor">Bedroom :</p>
            <p className="mx-5 number">{data.beds}</p>
          </div>
          <div className="floorDetails d-flex mt-3">
            <p className="floor">Bathroom :</p>
            <p className="mx-5 number">{data.bathrooms}</p>
          </div>
          <div className="floorDetails d-flex mt-3">
            <p className="floor">Start Date :</p>
            <p className="mx-5 number">{start}</p>
          </div>
          <div className="floorDetails d-flex mt-3">
            <p className="floor">End Date :</p>
            <p className="mx-5 number">{end}</p>
          </div>
          <div className=" mt-3 ">
            <p className="floor" style={{ fontSize: "35px", color: "grey" }}>
              Images :
            </p>
            <div className="row mt-3">
              <div className="col-md-3">
                <img src="/images/slider1.jpeg" alt="image" />
              </div>
              <div className="col-md-3">
                <img src="/images/slider1.jpeg" alt="image" />
              </div>
              <div className="col-md-3">
                <img src="/images/slider1.jpeg" alt="image" />
              </div>
            </div>

            <div className="text-center mt-4">
              <button className="btn-primary" onClick={navigateBook}>
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlatDetails;
