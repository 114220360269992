import React, { useMemo, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { EditIcon, DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";
// Custom components
import Card from "components/card/Card";
import TableHeader from "components/tableHeader";
import AdminSidebar from "layouts/admin";
import { MDBDataTableV5 } from "mdbreact";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IconButton } from "@chakra-ui/react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default function Flat({}) {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [Editopen, setEditOpen] = useState(false);
  const onEditOpenModal = () => setEditOpen(true);
  const onEditCloseModal = () => setEditOpen(false);
  const [floorData, setFloorData] = useState([]);

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "Unit No",
        field: "id",
        width: 270,
      },
      {
        label: "Unit type",
        field: "type",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },

      {
        label: "Price",
        field: "price",
        width: 100,
      },
      {
        label: "cleaning Fees",
        field: "cleaningFees",
        width: 100,
      },
      {
        label: "start Date",
        field: "startDate",
        width: 100,
      },
      {
        label: "end Date",
        field: "endDate",
        width: 100,
      },
      {
        label: "Description",
        field: "description",
        width: 100,
      },
      {
        label: "guests",
        field: "guests",
        width: 100,
      },
      {
        label: "bathrooms",
        field: "bathrooms",
        width: 100,
      },
      // {
      //   label: "Image",
      //   field: "image",
      //   width: 200,
      // },
      {
        label: "Action",
        field: "actions",
        width: 100,
      },

      // {
      //   label: (
      //     <button onClick={onOpenModal} className="btn btn-primary">
      //       Add
      //     </button>
      //   ),
      //   field: "button",
      //   width: 100,
      // },
    ],
    rows: [
      {
        name: "Tiger Nixon",
        position: "System Architect",
        office: "Edinburgh",
        age: "61",
        date: "2011/04/25",
        salary: "$320",
      },
    ],
  });

  const [fileName, setFileName] = useState("");
  const [base64, setBase64] = useState([]);
  const [currentBlogId, setCurrentBlogId] = useState(null);

  const onFloorChange = (val) => {
    const floorId = +val;
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/flat`, {
        params: { floor: floorId },
      })
      .then((response) => {
        const data = response?.data?.map((item) => {
          // Convert buffer array to base64 string
          // const base64Image = btoa(
          //   new Uint8Array(item?.images[0]?.data).reduce(
          //     (binary, byte) => binary + String.fromCharCode(byte),
          //     ""
          //   )
          // );

          return {
            id: item.unitNo,
            type: item.name,
            price: item.price,
            cleaningFees: item.cleaningFees,
            startDate: moment(item.startDate).format("DD/MM/YYYY"),
            // Image: item.images[0],
            endDate: moment(item.endDate).format("DD/MM/YYYY"),
            description: item.description,
            guests: item.guests,
            bathrooms: item.bathrooms,

            image: (
              <img
                src={item.image
                  // base64Image.includes("/jpegbase64")
                  //   ? `data:image/jpeg;base64,${base64Image}`
                  //   : `data:image/png;base64,${base64Image}`
                }
                alt="hello"
                style={{ maxHeight: "5rem", maxWidth: "70%" }}
              />
            ),
            actions: (
              <div>
                <button onClick={() => handleView(item.id)}>
                  <IconButton
                    icon={<ViewIcon />}
                    aria-label="View"
                    size="sm"
                    colorScheme="blue"
                    mr="2"
                  />
                </button>
                <button className="mx-3" onClick={() => handleEdit(item.id)}>
                  <IconButton
                    icon={<EditIcon />}
                    aria-label="Edit"
                    size="sm"
                    colorScheme="blue"
                    mr="2"
                  />
                </button>
              </div>
            ),
          };
        });

        setDatatable((prevState) => ({
          ...prevState,
          rows: data,
        }));
      });
  };

  const history = useHistory();

  const handleEdit = (id) => {
    history.push("/admin/editFlat", { id });
  };

  const handleView = (id) => {
    history.push("/admin/flatInformation", { id });
  };

  const handleEditSubmit = (values) => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/property/${currentBlogId}`,
        values
      )
      .then((response) => {
        console.log(response.data);
        // Close the modal and reset the form
        onEditCloseModal();

        // Optionally, you can update the datatable state to reflect the updated data
        // getGalleryData();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getFloorData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/floors`)
      .then((response) => {
        setFloorData(response.data.floorsData);
      })
      .catch((error) => {
        Swal.fire(
          "Error",
          "An error occurred while deleting the file.",
          "error"
        );
      });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/property/${id}`)
          .then((response) => {
            // getGalleryData();
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            console.log(`Blog with ID ${id} deleted successfully.`);
            console.log(response.data.message);
            // Optionally, you can update the datatable state to reflect the updated data without the deleted blog.
          })
          .catch((error) => {
            Swal.fire(
              "Error",
              "An error occurred while deleting the file.",
              "error"
            );
            console.error(`Error deleting blog with ID ${id}:`, error);
          });
      }
    });
  };

  const AddBlog = (values) => {
    console.log(values, "values");
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/flat`, values)
      .then((response) => {
        // getGalleryData();
        onCloseModal();
        // Optionally, you can update the datatable state to reflect the updated data without the deleted blog.
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFileChange = async (event, setFieldValue) => {
    // setFieldValue("fileName", event.target.value.split("\\")[2]);

    const file = event.target.files[0];

    const base64String = await convertFileToBase64(file);
    console.log(base64String, "funtion");
    setFieldValue("image", base64String);
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    onFloorChange("1");
    getFloorData();
  }, []);

  const [myBlogData, setMyBlogData] = useState({
    floorId: "",
    unitNo: "",
    description: "",
    cleaningFees: "",
    startDate: "",
    endDate: "",
    bathrooms: "",
    bedrooms: "",
    images: [],
    price: "",
  });

  return (
    <>
      <AdminSidebar>
        <Card>
          <Modal open={open} onClose={onCloseModal} center>
            <Formik
              initialValues={myBlogData}
              onSubmit={(values, { resetForm }) => {
                AddBlog(values);
                onCloseModal();
                resetForm();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div class="form-group">
                    <label for="title" class="col-form-label">
                      flat No :
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="title"
                      name="unitNo"
                      value={values.unitNo}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <div className="dropdown">
                      <select onChange={handleChange}>
                        <option> Select Floor</option>
                        {console.log(floorData, "floorData")}
                        {floorData.map((item, index) => (
                          <option key={item.floorId} value={item.floorId}>
                            {item.floorName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="title" class="col-form-label">
                      cleaning Fees:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="title"
                      name="cleaningFees"
                      value={values.cleaningFees}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label for="title" class="col-form-label">
                      start Date:
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      id="title"
                      name="startDate"
                      value={values.startDate}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label for="title" class="col-form-label">
                      start Date:
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      id="title"
                      name="endDate"
                      value={values.endDate}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label for="description" class="col-form-label">
                      Description:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label for="description" class="col-form-label">
                      Price:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="price"
                      name="price"
                      value={values.price}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">bathrooms:</label>
                    <input
                      type="text"
                      class="form-control"
                      id="bathrooms"
                      name="bathrooms"
                      value={values.bathrooms}
                      onChange={handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label class="col-form-label">bedrooms:</label>
                    <input
                      type="text"
                      class="form-control"
                      id="bedrooms"
                      name="bedrooms"
                      value={values.bedrooms}
                      onChange={handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label
                      for="recipient-name"
                      class="col-form-label"
                      onChange={handleChange}
                    >
                      Select Image:
                    </label>
                    <input
                      type="file"
                      class="form-control"
                      id="image"
                      name="image"
                      onChange={(e) => handleFileChange(e, setFieldValue)}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </form>
              )}
            </Formik>
          </Modal>
          <Formik
            initialValues={{
              selectedFloor: "1",
            }}
          >
            {({
              values,
              setFieldValue,
              /* and other goodies */
            }) => (
              <div>
                <label htmlFor="selectedFruit">Select a Floor</label>
                <Field
                  as="select"
                  name="selectedFloor"
                  id="selectedFloor"
                  className="form-control"
                  style={{ width: "10rem", marginBottom: "-4rem" }}
                  onChange={(e) => {
                    onFloorChange(e.target.value);
                    setFieldValue("selectedFloor", e.target.value);
                  }}
                  value={values.selectedFloor}
                >
                  <option value="1">floor 1</option>
                  <option value="2">floor 2</option>
                  <option value="3">floor 3</option>
                </Field>
              </div>
            )}
          </Formik>
          <MDBDataTableV5
            hover
            entriesOptions={[10, 20, 25]}
            entries={10}
            pagesAmount={4}
            data={datatable}
          />
        </Card>
      </AdminSidebar>
    </>
  );
}
