import { combineReducers } from "redux";

const initialState = {
  isAuthenticated: false,
  user: null,
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

export const rootReducer = combineReducers({
  login: loginReducer,
});
