import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdClose,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";
import Gallery from "views/admin/gallery";
import Amenities from "views/admin/amenities";
// Auth Imports
import GalleryCategory from "views/admin/galleryCategory";
import Users from "views/admin/users";
import ContactUs from "views/admin/contactUs";
import Testimonials from "views/admin/testimonials";
import Blog from "views/admin/Blog/blog";
import Property from "views/admin/property/prpperty";
import Complaints from "views/admin/complaints/complaints";
import Floor from "views/admin/floor/floor";
import Flat from "views/admin/flat/flat";
import MinimunStay from "views/admin/minimum_stays/MinimunStay";
import EditFlat from "views/admin/editFlat/editflat";
import ViewFlat from "views/admin/flatInformation/flatInformation";
import Accept from "views/admin/accept/accept";
import Approved from "views/admin/approve/approved";

const routes = [
  // {
  //   name: "Main Dashboard",
  //   layout: "/admin",
  //   path: "/default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: MainDashboard,
  // },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  {
    name: "Users",
    layout: "/admin",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    path: "/users",
    component: Users,
  },
  {
    name: "Bookings",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/bookings",
    component: DataTables,
  },

  // {
  //   name: "Sign In",
  //   layout: "/admin",
  //   path: "/",
  //   icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
  //   component: SignInCentered,
  //   hidden: true,
  // },
  // {
  //   name: "Website setting",
  //   layout: "/admin",
  //   path: "/website-setting",
  //   icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
  //   component: Profile,
  // },
  // {
  //   name: "About us",
  //   layout: "/admin",
  //   path: "/about-us",
  //   icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
  //   component: Profile,
  // },
  {
    name: "Amenities",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/amenities",
    component: Amenities,
  },
  {
    name: "Gallery Category",
    layout: "/admin",
    path: "/category",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: GalleryCategory,
  },
  {
    name: "Gallery",
    layout: "/admin",
    path: "/gallery",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Gallery,
  },
  // {
  //   name: "Contact Us",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   path: "/contact-us",
  //   component: ContactUs,
  // },
  {
    name: "Testimonials",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/testimonials",
    component: Testimonials,
  },
  {
    name: "Blog",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/blog",
    component: Blog,
  },
  {
    name: "Property",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/property",
    component: Property,
  },
  {
    name: "Complaints",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/complaints",
    component: Complaints,
  },
  {
    name: "Floor",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/floor",
    component: Floor,
  },
  {
    name: "Flat",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/flat",
    component: Flat,
  },
  {
    name: "Minimum Stays",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/minimun-stays",
    component: MinimunStay,
  },
  {
    name: "EditFlat",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/editFlat",
    component: EditFlat,
  },
  {
    name: "Requested Booking",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/paymentRequest",
    component: Accept,
  },
  {
    name: "Approved Booking",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/approved",
    component: Approved,
  },
  {
    // name: "EditFlat",
    layout: "/admin",
    // icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/flatInformation",
    component: ViewFlat,
  },

  // {
  //   name: "Blog",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   path: "/Blog",
  //   component: Blog,
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
];

export default routes;
