import React, { useMemo, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";
// Custom components
import Card from "components/card/Card";
import TableHeader from "components/tableHeader";
import AdminSidebar from "layouts/admin";
import { MDBDataTableV5 } from "mdbreact";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IconButton } from "@chakra-ui/react";
import "react-responsive-modal/styles.css";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import moment from "moment";

export default function Accept({}) {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "id",
        field: "id",
        width: 270,
      },
      {
        label: "status",
        field: "status",
        width: 150,
      },
      {
        label: "full Name",
        field: "fullName",
        width: 150,
      },
      {
        label: "start Date",
        field: "startDate",
        width: 150,
      },
      {
        label: "end Date",
        field: "endDate",
        width: 150,
      },
      {
        label: "price Per Day",
        field: "pricePerDay",
        width: 150,
      },
      {
        label: "deposit",
        field: "deposit",
        width: 150,
      },
      {
        label: "cleaning Fees",
        field: "cleaningFees",
        width: 150,
      },
      {
        label: "Action",
        field: "actions",
        width: 100,
      },
    ],
  });

  const AcceptPayment = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, accept it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/capture-payment`, {
            xRefNum: data.userXrefNum,
            bookingId: data.id,
          })
          .then((response) => {
            getGalleryData();
            Swal.fire(
              "Succes!",
              "Your Booking Request Accepted Succesfully.",
              "success"
            );

            // Optionally, you can update the datatable state to reflect the updated data without the deleted blog.
          })
          .catch((error) => {
            Swal.fire(
              "Error",
              "An error occurred while deleting the file.",
              "error"
            );
            console.error(`Error deleting blog with ID ${data.id}:`, error);
          });
      }
    });
  };

  const DeniedPayment = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, denied it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/release-payment`, {
            xRefNum: data.userXrefNum,
            bookingId: data.id,
          })
          .then((response) => {
            getGalleryData();
            Swal.fire(
              "Succes!",
              "Your Booking Request Denied Succesfully.",
              "success"
            );

            // Optionally, you can update the datatable state to reflect the updated data without the deleted blog.
          })
          .catch((error) => {
            Swal.fire(
              "Error",
              "An error occurred while deleting the file.",
              "error"
            );
            console.error(`Error deleting blog with ID ${data.id}:`, error);
          });
      }
    });
  };

  const getGalleryData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/requested-bookings?status=requested`)
      .then((response) => {
        console.log(response, "response");
        const data = response.data.data.map((item) => {
          // Convert buffer array to base64 string
          // console.log(item, "item");

          return {
            id: item.id,
            status: item.status,
            fullName: item.fullName,
            startDate: moment(item.startDate).format("DD/MM/YYYY"),
            endDate: moment(item.endDate).format("DD/MM/YYYY"),
            pricePerDay: item.pricePerDay,
            deposit: item.deposit,
            cleaningFees: item.cleaningFees,

            actions: (
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    AcceptPayment(item);
                  }}
                >
                  Accept
                </button>
                <button
                  className="btn btn-danger mx-3"
                  onClick={() => {
                    DeniedPayment(item);
                  }}
                >
                  Reject
                </button>
              </div>
            ),
          };
        });

        setDatatable((prevState) => ({
          ...prevState,
          rows: data,
        }));
      });
  };

  useEffect(() => {
    getGalleryData();
  }, []);

  return (
    <>
      <AdminSidebar>
        <Card>
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={datatable}
          />
        </Card>
      </AdminSidebar>
    </>
  );
}
