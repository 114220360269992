import React, { useMemo, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";
// Custom components
import Card from "components/card/Card";
import TableHeader from "components/tableHeader";
import AdminSidebar from "layouts/admin";
import { MDBDataTableV5 } from "mdbreact";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IconButton } from "@chakra-ui/react";
import "react-responsive-modal/styles.css";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

export default function Floor({}) {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "id",
        field: "id",
        width: 270,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Action",
        field: "actions",
        width: 100,
      },

      {
        label: (
          <button onClick={onOpenModal} className="btn btn-primary">
            Add
          </button>
        ),
        field: "button",
        width: 100,
      },
    ],
  });

  const getGalleryData = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/floors`).then((response) => {
      console.log(response, "response");
      const data = response.data.floorsData.map((item) => {
        // Convert buffer array to base64 string
        console.log(item, "item");

        return {
          id: item.floorId,
          name: item.floorName,

          //       mobile_number: item.mobile_number,
          //       unit_number: item.unit_number,
          //       subject: item.subject,

          actions: (
            <div>
              <button onClick={() => handleDelete(item.floorId)}>
                <IconButton
                  icon={<DeleteIcon />}
                  aria-label="Delete"
                  size="sm"
                  colorScheme="blue"
                  mr="2"
                />
              </button>
            </div>
          ),
        };
      });

      setDatatable((prevState) => ({
        ...prevState,
        rows: data,
      }));
    });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/floors/${id}`)
          .then((response) => {
            getGalleryData();
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            console.log(`Blog with ID ${id} deleted successfully.`);
            console.log(response.data.message);
            // Optionally, you can update the datatable state to reflect the updated data without the deleted blog.
          })
          .catch((error) => {
            Swal.fire(
              "Error",
              "An error occurred while deleting the file.",
              "error"
            );
            console.error(`Error deleting blog with ID ${id}:`, error);
          });
      }
    });
  };

  const [myBlogData, setMyBlogData] = useState({
    floorName: "",
  });

  const AddBlog = (values) => {
    console.log(values, "values");
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/floors`, values)
      .then((response) => {
        getGalleryData();
        onCloseModal();
        // Optionally, you can update the datatable state to reflect the updated data without the deleted blog.
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Floor Added Succesfully',
            showConfirmButton: false,
            timer: 1500
          })
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getGalleryData();
  }, []);

  return (
    <>
      <AdminSidebar>
        <Card>
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={datatable}
          />
        </Card>
        <Modal open={open} onClose={onCloseModal} center>
          <Formik
            initialValues={myBlogData}
            onSubmit={(values, { resetForm }) => {
              AddBlog(values);
              onCloseModal();
              resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div class="form-group">
                  <label for="title" class="col-form-label">
                    Floor Name:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="title"
                    name="floorName"
                    value={values.floorName}
                    onChange={handleChange}
                  />
                </div>

                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </form>
            )}
          </Formik>
        </Modal>
      </AdminSidebar>
    </>
  );
}
