import preloader from "./images/site/preloader.gif";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ts1 from "./images/testimonials/ts-1.jpg";
import { Modal, Button, Form, Nav, Container, Navbar } from "react-bootstrap";
import axios from "axios";
import { useDispatch } from "react-redux";
import { login, logout } from "../../../redux/actions";
import { loginReducer } from "redux/reducers";
import { useSelector } from "react-redux";

const Header = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [user, dispatch] = useReducer(loginReducer, {
  //   isAuthenticated: false,
  //   user: null,
  // });

  const user = useSelector((state) => state.login);

  const [expanded, setExpanded] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [mobileMenu, showMobileMenu] = useState(
    window.innerWidth <= 1024 ? true : false
  );
  const [show, setShow] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [errMsg, setMsg] = useState("");

  const clearLoginForm = () => {
    setEmail("");
    setPassword("");
    setName("");
    setMsg("");
  };
  const handleClose = () => {
    setShow(false);
    setIsLogin(true);
    clearLoginForm();
  };
  const handleShow = () => {
    setShow(true);
    setIsLogin(true);
    clearLoginForm();
  };
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleToggle = () => {
    setExpanded((expanded) => !expanded);
    document.body.classList.toggle("navbar-expanded", !expanded);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLogin) {
      // Handle login submit
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/login`, {
          email,
          password,
        })
        .then((data) => {
          localStorage.setItem("token", data.data.token);
          // localStorage.setItem("userid", data.data.user.id);
          // localStorage.setItem("name", data.data.user.name);
          dispatch(login(data.data.user));
          // setUserName(data.data.name);
          handleClose();
          // setFileName("");
          // getGalleryData();
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            setMsg(err.response.data.message);
          }
        });
    } else {
      // Handle registration submit
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/register`, {
          name,
          email,
          mobile,
          password,
        })
        .then((data) => {
          handleShow();
          // setFileName("");
          // getGalleryData();
        })
        .catch((err) => {
          // setFileName("");
        });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        showMobileMenu(true);
      } else {
        showMobileMenu(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <header id="header-container">
        {/* <!-- Header --> */}
        <div id="header" className="int_content_wraapper hd">
          <div className="container container-header">
            {/* <!-- Left Side Content --> */}
            <div className="left-side">
              {/* <!-- Logo --> */}
              <div id="logo">
                <a href="index.html">
                  <img
                    src={preloader}
                    style={{ width: 50, height: 50, marginLeft: -10 }}
                    data-sticky-logo="images/preloader1.png"
                    alt=""
                  />
                </a>
              </div>

              {/* <nav id="navigation" className="style-1 white">
                <ul
                  id="responsive"
                  className={`menu ${showMenu ? "show-menu" : ""}`}
                >
                  <li>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/");
                      }}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/about");
                      }}
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        // history.push("/about");
                      }}
                    >
                      Apartments
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/amenities");
                      }}
                    >
                      Amenities
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/gallery");
                      }}
                    >
                      Gallery
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/contact");
                      }}
                    >
                      Contact Us
                    </a>
                  </li>

                  <li className="d-none d-xl-none d-block d-lg-block">
                    <a href="login.html">Login</a>
                  </li>
                  <li className="d-none d-xl-none d-block d-lg-block">
                    <a href="register.html">Register</a>
                  </li>
                  <li className="d-none d-xl-none d-block d-lg-block mt-5 pb-4 ml-5 border-bottom-0">
                    <a
                      href="#"
                      className="button border btn-lg btn-block text-center"
                    >
                      View Availiability<i className="fas fa-laptop-house ml-2"></i>
                    </a>
                  </li>
                </ul>
              </nav> */}
              <Navbar
                bg="light"
                expand="lg"
                expanded={expanded}
                onToggle={handleToggle}
                // sticky="top"
                style={{
                  width: "fit-content",
                }}
              >
                <Container>
                  {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                      <Nav.Link
                        onClick={(e) => {
                          e.preventDefault();
                          history.push("/");
                        }}
                      >
                        Home
                      </Nav.Link>
                      <Nav.Link
                        onClick={(e) => {
                          e.preventDefault();
                          history.push("/about");
                        }}
                      >
                        About Us
                      </Nav.Link>
                      <Nav.Link
                        onClick={(e) => {
                          e.preventDefault();
                          history.push("/amenities");
                        }}
                      >
                        Amenities
                      </Nav.Link>
                      <Nav.Link
                        onClick={(e) => {
                          e.preventDefault();
                          history.push("/gallery");
                        }}
                      >
                        Gallery
                      </Nav.Link>
                      <Nav.Link
                        onClick={(e) => {
                          e.preventDefault();
                          history.push("/contact");
                        }}
                      >
                        Contact Us
                      </Nav.Link>
                      <Nav.Link
                        onClick={(e) => {
                          e.preventDefault();
                          history.push("/booking");
                        }}
                      >
                        Book
                      </Nav.Link>
                      {/* <Nav.Link>
                        <span
                          onClick={() => {
                            history.push("/admin");
                          }}
                        >
                          Admin Panel
                        </span>
                      </Nav.Link> */}
                      {!localStorage.getItem("token") && (
                        <Nav.Link
                          onClick={(e) => {
                            e.preventDefault();
                            handleShow();
                          }}
                        >
                          Login/Register
                        </Nav.Link>
                      )}
                      {/* <Nav.Link>Register</Nav.Link> */}
                      {/* <Nav.Link className="d-none d-xl-none d-block d-lg-block mt-5 pb-4 ml-5 border-bottom-0">
                        View Availiability
                        <i className="fas fa-laptop-house ml-2"></i>
                      </Nav.Link> */}
                      {localStorage.getItem("token") && (
                        <Nav.Link
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("/profile");
                          }}
                        >
                          User Profile
                        </Nav.Link>
                      )}
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
              {/* <!-- Main Navigation / End --> */}
            </div>
            {/* <!-- Left Side Content / End --> */}

            {/* <!-- Right Side Content / End --> */}
            {/* <div className="right-side d-none d-none d-lg-none d-xl-flex">
              <div className="header-widget">
                <a href="#" className="button border">
                  Availiability
                </a>
              </div>
            </div> */}
            {/* <!-- Right Side Content / End --> */}

            {/* <!-- Right Side Content / End --> */}
            {localStorage.getItem("token") && (
              <div className="header-user-menu user-menu add">
                <div className="header-user-name">
                  <span>
                    {localStorage.getItem("token") && <img src={ts1} alt="" />}
                  </span>
                  {/* Hi, Mary! */}
                  {localStorage.getItem("token") &&
                    localStorage.getItem("name")}
                </div>
                <span
                  onClick={() => {
                    localStorage.clear();
                    dispatch(logout());
                  }}
                >
                  {localStorage.getItem("token") && "Logout"}
                </span>

                <ul>
                  <li>
                    <a href="user-profile.html"> Edit profile</a>
                  </li>
                  <li>
                    <a href="#"> Add Property</a>
                  </li>
                  <li>
                    <a href="payment-method.html"> Payments</a>
                  </li>
                  <li>
                    <a href="change-password.html"> Change Password</a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(logout());
                      }}
                    >
                      Log Out
                    </a>
                  </li>
                </ul>
              </div>
            )}
            {/* <!-- Right Side Content / End --> */}

            {/* <div className="right-side d-none d-none d-lg-none d-xl-flex sign ml-0">
              <div className="header-widget sign-in">
                <div className="show-reg-form modal-open">
                  <a href="#">Sign In</a>
                </div>
              </div>
            </div> */}
            {/* <!-- Right Side Content / End --> */}
          </div>
        </div>
        {/* <!-- Header / End --> */}
      </header>
      {/* <!--register form --> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isLogin ? "Login" : "Registration"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {!isLogin && (
              <Form.Group controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            )}
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            {!isLogin && (
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Mobile Number"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Form.Group>
            )}
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <div className="text-danger">{errMsg}</div>
            <a
              variant="secondary"
              onClick={() => {
                setIsLogin(!isLogin);
                clearLoginForm();
              }}
              style={{
                marginTop: 10,
                textDecoration: "underline",
              }}
            >
              {isLogin ? "Need to register?" : "Already have an account?"}
            </a>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            {isLogin ? "Login" : "Register"}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="login-and-register-form modal">
        <div className="main-overlay"></div>
        <div className="main-register-holder">
          <div className="main-register fl-wrap">
            <div className="close-reg">
              <i className="fa fa-times"></i>
            </div>
            <h3>
              Welcome to{" "}
              <span>
                Find<strong>Houses</strong>
              </span>
            </h3>
            <div className="soc-log fl-wrap">
              <p>Login</p>
              <a href="#" className="facebook-log">
                <i className="fa fa-facebook-official"></i>Log in with Facebook
              </a>
              <a href="#" className="twitter-log">
                <i className="fa fa-twitter"></i> Log in with Twitter
              </a>
            </div>
            <div className="log-separator fl-wrap">
              <span>Or</span>
            </div>
            <div id="tabs-container">
              <ul className="tabs-menu">
                <li className="current">
                  <a href="#tab-1">Login</a>
                </li>
                <li>
                  <a href="#tab-2">Register</a>
                </li>
              </ul>
              <div className="tab">
                <div id="tab-1" className="tab-contents">
                  <div className="custom-form">
                    <form method="post" name="registerform">
                      <label>Username or Email Address * </label>
                      <input
                        name="email"
                        type="text"
                        // onClick="this.select()"
                        value=""
                      />
                      <label>Password * </label>
                      <input
                        name="password"
                        type="password"
                        // onClick="this.select()"
                        value=""
                      />
                      <button type="submit" className="log-submit-btn">
                        <span>Log In</span>
                      </button>
                      <div className="clearfix"></div>
                      <div className="filter-tags">
                        <input id="check-a" type="checkbox" name="check" />
                        <label htmlFor="check-a">Remember me</label>
                      </div>
                    </form>
                    <div className="lost_password">
                      <a href="#">Lost Your Password?</a>
                    </div>
                  </div>
                </div>
                <div className="tab">
                  <div id="tab-2" className="tab-contents">
                    <div className="custom-form">
                      <form
                        method="post"
                        name="registerform"
                        className="main-register-form"
                        id="main-register-form2"
                      >
                        <label>First Name * </label>
                        <input
                          name="name"
                          type="text"
                          // onClick="this.select()"
                          value=""
                        />
                        <label>Second Name *</label>
                        <input
                          name="name2"
                          type="text"
                          // onClick="this.select()"
                          value=""
                        />
                        <label>Email Address *</label>
                        <input
                          name="email"
                          type="text"
                          // onClick="this.select()"
                          value=""
                        />
                        <label>Password *</label>
                        <input
                          name="password"
                          type="password"
                          // onClick="this.select()"
                          value=""
                        />
                        <button type="submit" className="log-submit-btn">
                          <span>Register</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="clearfix"></div>
    </>
  );
};

export default Header;
