import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router-dom";
import LeftBar from "../common/leftbar";
import Header from "../common/header";
import Footer from "../common/footer";
import fp8 from "../common/images/feature-properties/fp-8.jpg";
import fp9 from "../common/images/feature-properties/fp-9.jpg";
import fp10 from "../common/images/feature-properties/fp-10.jpg";
import fp11 from "../common/images/feature-properties/fp-11.jpg";
import fp12 from "../common/images/feature-properties/fp-12.jpg";
import p1 from "../common/images/interior/p-1.jpg";
import p2 from "../common/images/interior/p-2.jpg";
import p3 from "../common/images/interior/p-3.jpg";
import p4 from "../common/images/interior/p-4.jpg";
import p5 from "../common/images/interior/p-5.jpg";
import s1 from "../common/images/single-property/s-1.jpg";
import s2 from "../common/images/single-property/s-2.jpg";
import s3 from "../common/images/single-property/s-3.jpg";
import s6 from "../common/images/single-property/s-6.jpg";
import preloader from "../common/images/site/preloader.gif";
import ts1 from "../common/images/testimonials/ts-1.jpg";
import ts2 from "../common/images/testimonials/ts-2.jpg";
import ts3 from "../common/images/testimonials/ts-3.jpg";
import ts4 from "../common/images/testimonials/ts-4.jpg";
import ts5 from "../common/images/testimonials/ts-5.jpg";
import ts6 from "../common/images/testimonials/ts-6.jpg";
import slider8 from "../common/images/slider/slider-8.jpg";
import slider9 from "../common/images/slider/slider-9.jpg";
import b1 from "../common/images/blog/b-1.jpg";
import b2 from "../common/images/blog/b-2.jpg";
import b3 from "../common/images/blog/b-3.jpg";
import b4 from "../common/images/blog/b-4.jpg";
import drone1 from "../common/images/site/drone1.png";
import portfolio1 from "../common/images/site/0.jpg";
import portfolio2 from "../common/images/site/1.jpg";
import portfolio3 from "../common/images/site/2.jpg";
import portfolio4 from "../common/images/site/1.jpg";
import portfolio5 from "../common/images/site/4.jpg";
import portfolio6 from "../common/images/site/drone3.png";
import portfolio7 from "../common/images/site/4.jpg";
import portfolio8 from "../common/images/site/0.jpg";

import "../common/css/jquery-ui.css";
import "../common/css/fontawesome-all.min.css";
import "../common/css/fontawesome-5-all.min.css";
import "../common/css/font-awesome.min.css";
import "../common/css/search.css";
import "../common/css/animate.css";
import "../common/css/aos2.css";
import "../common/css/swiper.min.css";
import "../common/css/magnific-popup.css";
import "../common/css/lightcase.css";
import "../common/css/bootstrap.min.css";
import "../common/css/menu.css";
import "../common/css/default.css";
import "../common/css/styles.css";
import axios from "axios";

const propertyList = [
  {
    image: "slider8",
    type: "For Rent",
    category: "Estate",
    title: "Luxury Villa House",
    price: "$ 230,000",
    location: "Est St, 77 - Central Park South, NYC",
    bed: "6 Bedrooms",
    bath: "3 Bathrooms",
    area: "720 sq ft",
    garages: "2 Garages",
  },
  // {
  //   image: "slider9",
  //   type: "For Rent",
  //   category: "Estate",
  //   title: "Luxury Villa House",
  //   price: "$ 4,600",
  //   location: "Est St, 77 - Central Park South, NYC",
  //   bed: "6 Bedrooms",
  //   bath: "3 Bathrooms",
  //   area: "720 sq ft",
  //   garages: "2 Garages",
  // },
];

const portfolioItems = [
  {
    img: portfolio1,
    title: "Luxury House 1",
    price: "$230,000",
    bed: 6,
    bath: 3,
    sqft: "720 sq ft",
  },
  {
    img: portfolio2,
    title: "Family Apartment",
    price: "$230,000",
    bed: 6,
    bath: 3,
    sqft: "720 sq ft",
  },
  {
    img: portfolio3,
    title: "Villa House",
    price: "$230,000",
    bed: 6,
    bath: 3,
    sqft: "720 sq ft",
  },
  {
    img: portfolio4,
    title: "Luxury Condo",
    price: "$230,000",
    bed: 6,
    bath: 3,
    sqft: "720 sq ft",
  },
  {
    img: drone1,
    title: "Luxury House",
    price: "$230,000",
    bed: 6,
    bath: 3,
    sqft: "720 sq ft",
  },
  {
    img: portfolio5,
    title: "Luxury House",
    price: "$230,000",
    bed: 6,
    bath: 3,
    sqft: "720 sq ft",
  },
  {
    img: portfolio6,
    title: "Luxury House",
    price: "$230,000",
    bed: 6,
    bath: 3,
    sqft: "720 sq ft",
  },
  {
    img: portfolio7,
    title: "Luxury House",
    price: "$230,000",
    bed: 6,
    bath: 3,
    sqft: "720 sq ft",
  },
  {
    img: portfolio4,
    title: "Luxury House",
    price: "$230,000",
    bed: 6,
    bath: 3,
    sqft: "720 sq ft",
  },
];

// const PortfolioData = (props) => {
//   return (
//     <div className="agents-grid">
//       <div
//         className="landscapes listing-item compact thehp-1"
//         data-aos="fade-up"
//         data-aos-delay="150"
//       >
//         <a href="#" className="recent-16">
//           <div
//             className="recent-img16 img-fluid img-center"
//             style={{ backgroundImage: `url(${props.data.img})` }}
//           ></div>
//           <div className="recent-content"></div>
//           <div className="listing-badges">
//             <span>FOR RENT</span>
//           </div>
//           <div className="recent-details">
//             <div className="recent-title">{props.data.title}</div>
//             <div className="recent-price mb-3">$230,000</div>
//             <div className="house-details thehp-1">
//               <i className="fa fa-bed mr-1" aria-hidden="true"></i> 6 Bed{" "}
//               <span>|</span>
//               <i className="fa fa-bath mr-1" aria-hidden="true"></i> 3 Bath{" "}
//               <span>|</span>
//               <i className="fa fa-object-group mr-1" aria-hidden="true"></i> 720
//               sq ft
//             </div>
//           </div>
//           <div className="view-proper">View Details</div>
//         </a>
//       </div>
//     </div>
//   );
// };

const PortfolioData = ({ data }) => {
  const base64Image = btoa(
    new Uint8Array(data.image.data).reduce(
      (binary, byte) => binary + String.fromCharCode(byte),
      ""
    )
  );
  const cleanedBase64Image = base64Image.split("base64")[1];


  return (
    <div className="agents-grid">
      <div
        className="landscapes listing-item compact thehp-1"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <a href="/building" className="recent-16">
          <div
            className="recent-img16 img-fluid img-center"
            style={{
              backgroundImage: `url(data:image/jpeg;base64,${cleanedBase64Image})`,
            }}
          ></div>
          <div className="recent-content"></div>
          <div className="listing-badges">
            <span>FOR RENT</span>
          </div>
          <div className="recent-details">
            <div className="recent-title">{data.title}</div>
            <div className="recent-price mb-3">${data.price}</div>
            <div className="house-details thehp-1">
              <i className="fa fa-bed mr-1" aria-hidden="true"></i>{" "}
              {data.room_capacity} Bed <span>|</span>
              <i className="fa fa-bath mr-1" aria-hidden="true"></i>{" "}
              {data.num_washroom} Bath <span>|</span>
              <i
                className="fa fa-object-group mr-1"
                aria-hidden="true"
              ></i>{" "}
              {data.square_foot} sq ft
            </div>
          </div>
          <div className="view-proper">View Details</div>
        </a>
      </div>
    </div>
  );
};

const Home = () => {
  const history = useHistory();

  const [slidesNo, setSlidesNo] = useState(
    window.innerWidth > 1200 ? 4 : window.innerWidth > 768 ? 2 : 1
  );
  const [loader, showLoader] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded((expanded) => !expanded);
    document.body.classList.toggle("navbar-expanded", !expanded);
  };
  useEffect(() => {
    setTimeout(() => {
      showLoader(false);
    }, 6000);

    const handleResize = () => {
      if (window.innerWidth > 1200) {
        setSlidesNo(4);
      } else if (window.innerWidth > 768) {
        setSlidesNo(2);
      } else {
        setSlidesNo(1);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const options = {
    items: true,
    // loop: true,
    // nav: true,
    dots: false,
    // autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    navText: ["", ""],
  };
  const options2 = {
    items: 4,
    loop: true,
    nav: true,
    dots: false,
    // autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    navText: [
      `<div className="swiper-button-prev">Prev</div>`,
      `<div className="swiper-button-next">Next</div>`,
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1200: {
        items: 4,
      },
    },
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesNo,
    slidesToScroll: 1,
    centerMode: false,
    prevArrow: <></>, // custom arrow component that does not render anything
    nextArrow: <></>, // custom arrow component that does not render anything
    // dotsClass: "slick-dots",
  };

  const [property, setProperty] = useState([]);

  const getGalleryData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/property`)
      .then((response) => {
        console.log(response.data, "data");

        setProperty(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    // Extract the day and year from the formatted date
    const [, day, year] = formattedDate.split(" ");

    // Get the month abbreviation
    const month = date.toLocaleDateString("en-US", { month: "short" });

    return `${month} ${day}, ${year}`;
  }

  useEffect(() => {
    getGalleryData();
  }, []);

  return (
    <div
      className="inner-pages int_white_bg hd-white"
      style={{
        backgroundColor: "white",
      }}
    >
      {!loader && (
        <>
          <LeftBar />
          {/* <!-- START SECTION HEADINGS --> */}
          {/* <!-- Header Container */}
          {/* ================================================== --> */}
          <Header />
          {/* <!-- Header Container / End --> */}
          <div
            className="int_content_wraapper int_content_left"
            style={{
              marginTop: expanded && window.innerWidth < 990 ? 310 : 0,
            }}
          >
            {/* <!--===Start Revolution Slider===--> */}
            <div className="int_banner_slider">
              <div className="banner_box_wrapper">
                <div className="container-fluid">
                  <div className="row ">
                    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 align-self-center">
                      <div className="main_contentblock">
                        <OwlCarousel {...options}>
                          {propertyList.map((property, index) => (
                            <div key={index} className="item">
                              <div
                                className={`swiper_imgbox imgbox${index + 1}`}
                              >
                                <div className="swipper_img">
                                  <h4>
                                    {property.type}{" "}
                                    <span
                                      style={{
                                        fontSize: "90px",
                                        textTransform: "uppercase",
                                        fontWeight: 400,
                                        color: "#f1f1f1",
                                        letterSpacing: "12px",
                                        position: "absolute",
                                        left: "10px",
                                        right: 0,
                                        top: "-50px",
                                        zIndex: -1,
                                      }}
                                    >
                                      {property.category}
                                    </span>
                                  </h4>
                                  <h2
                                    style={{
                                      color: "#333",
                                    }}
                                  >
                                    {property.title}
                                  </h2>
                                  <h3>
                                    {property.price}
                                    <span className="banner_span1"></span>
                                  </h3>
                                  <p
                                    style={{
                                      color: "#333",
                                    }}
                                  >
                                    <i className="fa fa-map-marker mr-3"></i>
                                    {property.location}
                                  </p>
                                  {/* <!-- homes List --> */}
                                  <ul className="homes-list clearfix">
                                    <li
                                      style={{
                                        color: "#333",
                                      }}
                                    >
                                      <i
                                        className="fa fa-bed"
                                        aria-hidden="true"
                                        style={{
                                          color: "#333",
                                        }}
                                      ></i>
                                      <span>{property.bed}</span>
                                    </li>
                                    <li
                                      style={{
                                        color: "#333",
                                      }}
                                    >
                                      <i
                                        className="fa fa-bath"
                                        aria-hidden="true"
                                        style={{
                                          color: "#333",
                                        }}
                                      ></i>
                                      <span>{property.bath}</span>
                                    </li>
                                    <li
                                      style={{
                                        color: "#333",
                                      }}
                                    >
                                      <i
                                        className="fa fa-object-group"
                                        aria-hidden="true"
                                        style={{
                                          color: "#333",
                                        }}
                                      ></i>
                                      <span>{property.area}</span>
                                    </li>
                                    <li
                                      style={{
                                        color: "#333",
                                      }}
                                    >
                                      <i
                                        className="fas fa-warehouse"
                                        aria-hidden="true"
                                        style={{
                                          color: "#333",
                                        }}
                                      ></i>
                                      <span>{property.garages}</span>
                                    </li>
                                  </ul>
                                  <a href="/building" className="int_btn">
                                    View Property{" "}
                                    <span className="btn_caret">
                                      <i className="fas fa-caret-right"></i>
                                    </span>
                                  </a>
                                  <h1
                                    style={{
                                      fontsize: "90px",
                                      textTransform: "uppercase",
                                      color: "#f1f1f1",
                                      fontWeight: 600,
                                      marginTop: "85px",
                                      marginLeft: "-9px",
                                    }}
                                  >
                                    Real
                                  </h1>
                                </div>
                              </div>
                            </div>
                          ))}
                        </OwlCarousel>
                      </div>
                    </div>

                    <div className="col-xl-8 col-lg-8 col-md-7 align-self-center pr-0">
                      {/* <!--=== Swiper ===--> */}
                      <div className="main_imgblock">
                        <div className="swiper-container" data-aos="fade-left">
                          <div className="swiper-wrapper">
                            <div className="swiper-slide">
                              <div className="swiper_contbox">
                                <div className="swipper_conntent">
                                  <img
                                    src={drone1}
                                    className="img-fluid "
                                    alt="images"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="swiper-slide">
                              <div className="swiper_contbox">
                                <div className="swipper_conntent">
                                  <img
                                    src={portfolio4}
                                    className="img-fluid"
                                    alt="images"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!--=== Add Arrows ===--> */}
                  <div className="banner_navi">
                    <div
                      className="swiper-button-next"
                      style={{
                        color: "#111",
                      }}
                    >
                      Next
                    </div>
                    <div
                      className="swiper-button-prev"
                      style={{
                        color: "#111",
                      }}
                    >
                      Prev
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--===End Revolution Slider===--> */}
          </div>

          {/* <!-- START SECTION PROPERTIES FOR RENT --> */}
          <section
            className="recently portfolio featured bg-white-1 rec-pro"
            style={{
              padding: "7rem 0",
            }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="section-title col-md-5 pl-44">
                  <h3>Properties</h3>
                  <h2>FOR RENT</h2>
                </div>
              </div>
              <div className="portfolio col-xl-12 p-0">
                <Slider {...settings}>
                  {property.map((i) => (
                    <PortfolioData data={i} />
                  ))}

                  {/* {portfolioItems.map((i) => (
                    <PortfolioData data={i} />
                  ))} */}
                </Slider>
              </div>
            </div>
          </section>
          {/* <!-- END SECTION PROPERTIES FOR RENT --> */}

          {/* <!-- START SECTION SERVICES --> */}
          <section className="services-home rec-pro">
            <div className="container-fluid">
              <div className="section-title">
                <h3>Amenities</h3>
                <h2>LOFTS@JC</h2>
              </div>
              <div className="row">
                <div
                  className="col-lg-3 col-md-12 m-top-0 m-bottom-40"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                    <div className="media">
                      <i className="fa fa-car bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                    </div>
                    <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                      <h4 className="m-bottom-15 text-bold-700">PARKING</h4>
                      <p>
                        Gated, secured surface parking with electronic FOB entry
                        available.
                      </p>
                      <a
                        className="text-base text-base-dark-hover text-size-13"
                        href="properties-full-list.html"
                      >
                        Read More{" "}
                        <i className="fa fa-long-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-12 m-top-40 m-bottom-40"
                  data-aos="fade-up"
                  data-aos-delay="250"
                >
                  <div className="service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                    <div className="media">
                      <i className="fas fa-book bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                    </div>
                    <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                      <h4 className="m-bottom-15 text-bold-700">
                        STUDY LOUNGE
                      </h4>
                      <p>Private study lounge with seating for eight.</p>
                      <a
                        className="text-base text-base-dark-hover text-size-13"
                        href="properties-full-list.html"
                      >
                        Read More{" "}
                        <i className="fa fa-long-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-12 m-top-40 m-bottom-40 commercial"
                  data-aos="fade-up"
                  data-aos-delay="350"
                >
                  <div className="service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                    <div className="media">
                      <i className="fas fa-tint bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                    </div>
                    <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                      <h4 className="m-bottom-15 text-bold-700">LAUNDRY</h4>
                      <p>Washer and dryer included in every unit</p>
                      <a
                        className="text-base text-base-dark-hover text-size-13"
                        href="properties-full-list.html"
                      >
                        Read More{" "}
                        <i className="fa fa-long-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-12 m-top-40 m-bottom-40 commercial"
                  data-aos="fade-up"
                  data-aos-delay="450"
                >
                  <div className="service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                    <div className="media">
                      <i className="fas fa-bed bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                    </div>
                    <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                      <h4 className="m-bottom-15 text-bold-700">FURNITURE</h4>
                      <p>
                        Fully furnished bedrooms including full size bed and
                        mattress, individual desk and chair, and oversized
                        closet
                      </p>
                      <a
                        className="text-base text-base-dark-hover text-size-13"
                        href="properties-full-list.html"
                      >
                        Read More{" "}
                        <i className="fa fa-long-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div></div>

                <div
                  className="col-lg-3 col-md-12 m-top-40 m-bottom-40 commercial"
                  data-aos="fade-up"
                  data-aos-delay="450"
                  style={{ marginTop: "60px" }}
                >
                  <div className="service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                    <div className="media">
                      <i className="fas fa-hotel bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                    </div>
                    <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                      <h4 className="m-bottom-15 text-bold-700">
                        FITNESS CENTER
                      </h4>
                      <p>
                        Free access to exclusive state-of-the-art fitness center
                        in building.
                      </p>
                      <a
                        className="text-base text-base-dark-hover text-size-13"
                        href="properties-full-list.html"
                      >
                        Read More{" "}
                        <i className="fa fa-long-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-3 col-md-12 m-top-40 m-bottom-40 commercial"
                  data-aos="fade-up"
                  data-aos-delay="450"
                  style={{ marginTop: "60px" }}
                >
                  <div className="service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                    <div className="media">
                      <i className="fas fa-hotel bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                    </div>
                    <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                      <h4 className="m-bottom-15 text-bold-700">APPLIANCES</h4>
                      <p>
                        Stainless steel oven, refrigerator, dishwasher,
                        microwave, and ample cabinets.
                      </p>
                      <a
                        className="text-base text-base-dark-hover text-size-13"
                        href="properties-full-list.html"
                      >
                        Read More{" "}
                        <i className="fa fa-long-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-3 col-md-12 m-top-40 m-bottom-40 commercial"
                  data-aos="fade-up"
                  data-aos-delay="450"
                  style={{ marginTop: "60px" }}
                >
                  <div className="service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                    <div className="media">
                      <i className="fas fa-hotel bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                    </div>
                    <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                      <h4 className="m-bottom-15 text-bold-700">UTILITIES</h4>
                      <p>
                        Internet’High-speed managed WiFi/Stream TV and all other
                        utilities included.
                      </p>
                      <a
                        className="text-base text-base-dark-hover text-size-13"
                        href="properties-full-list.html"
                      >
                        Read More{" "}
                        <i className="fa fa-long-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-3 col-md-12 m-top-40 m-bottom-40 commercial"
                  data-aos="fade-up"
                  data-aos-delay="450"
                  style={{ marginTop: "60px" }}
                >
                  <div className="service bg-light-2 border-1 border-light box-shadow-1 box-shadow-2-hover">
                    <div className="media">
                      <i className="fas fa-hotel bg-base text-white rounded-100 box-shadow-1 p-top-5 p-bottom-5 p-right-5 p-left-5"></i>
                    </div>
                    <div className="agent-section p-top-35 p-bottom-30 p-right-25 p-left-25">
                      <h4 className="m-bottom-15 text-bold-700">LOCATION</h4>
                      <p>
                        Centrally located in the Tri-Bros-City area, next to two
                        BU campuses, the UHS-Wilson Medical Center.
                      </p>
                      <a
                        className="text-base text-base-dark-hover text-size-13"
                        href="properties-full-list.html"
                      >
                        Read More{" "}
                        <i className="fa fa-long-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- END SECTION SERVICES --> */}

          {/* <!-- START SECTION PROPERTIES Gallery --> */}
          <section className="recently portfolio bg-white-1 rec-pro">
            <div className="container-fluid">
              <div className="row">
                <div className="section-title col-md-5 pl-44">
                  <h3>Properties</h3>
                  <h2>GALLERY</h2>
                </div>
              </div>
              <div className="portfolio col-xl-12 p-0">
                {/* <div className="slick-lancers"> */}
                <Slider {...settings}>
                  <div
                    className="agents-grid"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    <div className="landscapes listing-item compact thehp-2">
                      <a href="#" className="recent-16">
                        <div
                          className="recent-img16 img-fluid img-center"
                          style={{ backgroundImage: `url(${portfolio1})` }}
                        ></div>
                        <div className="recent-content"></div>
                      </a>
                    </div>
                  </div>
                  <div
                    className="agents-grid"
                    data-aos="fade-up"
                    data-aos-delay="250"
                  >
                    <div className="people listing-item compact thehp-2">
                      <a href="#" className="recent-16">
                        <div
                          className="recent-img16 img-center"
                          style={{ backgroundImage: `url(${portfolio2})` }}
                        ></div>
                        <div className="recent-content"></div>
                      </a>
                    </div>
                  </div>
                  <div
                    className="agents-grid"
                    data-aos="fade-up"
                    data-aos-delay="350"
                  >
                    <div className="people landscapes no-pb pbp-3 listing-item compact thehp-2">
                      <a href="#" className="recent-16">
                        <div
                          className="recent-img16 img-center"
                          style={{ backgroundImage: `url(${portfolio8})` }}
                        ></div>
                        <div className="recent-content"></div>
                      </a>
                    </div>
                  </div>
                  <div
                    className="agents-grid"
                    data-aos="fade-up"
                    data-aos-delay="450"
                  >
                    <div className="landscapes listing-item compact thehp-2">
                      <a href="#" className="recent-16">
                        <div
                          className="recent-img16 img-center"
                          style={{ backgroundImage: `url(${portfolio3})` }}
                        ></div>
                        <div className="recent-content"></div>
                      </a>
                    </div>
                  </div>
                  <div
                    className="agents-grid"
                    data-aos="fade-up"
                    data-aos-delay="550"
                  >
                    <div className="people listing-item compact thehp-2">
                      <a href="#" className="recent-16">
                        <div
                          className="recent-img16 img-center"
                          style={{ backgroundImage: `url(${portfolio4})` }}
                        ></div>
                        <div className="recent-content"></div>
                      </a>
                    </div>
                  </div>
                  <div className="agents-grid" data-aos="fade-up">
                    <div className="people landscapes no-pb pbp-3 listing-item compact thehp-2">
                      <a href="#" className="recent-16">
                        <div
                          className="recent-img16 img-center"
                          style={{ backgroundImage: `url(${portfolio7})` }}
                        ></div>
                        <div className="recent-content"></div>
                      </a>
                    </div>
                  </div>
                  <div className="agents-grid" data-aos="fade-up">
                    <div className="people landscapes no-pb pbp-3 listing-item compact thehp-2">
                      <a href="#" className="recent-16">
                        <div
                          className="recent-img16 img-center"
                          style={{ backgroundImage: `url(${portfolio5})` }}
                        ></div>
                        <div className="recent-content"></div>
                      </a>
                    </div>
                  </div>
                  <div className="agents-grid" data-aos="fade-up">
                    <div className="people landscapes no-pb pbp-3 listing-item compact thehp-2">
                      <a href="#" className="recent-16">
                        <div
                          className="recent-img16 img-center"
                          style={{ backgroundImage: `url(${drone1})` }}
                        ></div>
                        <div className="recent-content"></div>
                      </a>
                    </div>
                  </div>
                  <div className="agents-grid" data-aos="fade-up">
                    <div className="people landscapes no-pb pbp-3 listing-item compact thehp-2">
                      <a href="#" className="recent-16">
                        <div
                          className="recent-img16 img-center"
                          style={{ backgroundImage: `url(${portfolio6})` }}
                        ></div>
                        <div className="recent-content"></div>
                      </a>
                    </div>
                  </div>
                </Slider>
                {/* </div> */}
              </div>
            </div>
          </section>
          {/* <!-- END SECTION PROPERTIES gallery --> */}

          <center>
            <a
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                history.push("/gallery");
              }}
            >
              View More
            </a>
          </center>

          {/* <!-- START SECTION TESTIMONIALS --> */}
          <section className="testimonials bg-white rec-pro">
            <div className="container-fluid">
              <div className="section-title">
                <h3>Happy</h3>
                <h2>Customers</h2>
              </div>
              {/* <div className="owl-carousel style1"> */}
              {/* <OwlCarousel {...options2}> */}
              <Slider {...settings}>
                <div className="test-1" data-aos="fade-up" data-aos-delay="150">
                  <h3>Lisa Smith</h3>
                  <img src={ts1} alt="" />
                  <h6 className="mt-2">New York</h6>
                  <ul className="starts text-center mb-2">
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet, ligula magna at etiam aliquip
                    venenatis. Vitae sit felis donec, suscipit tortor et sapien
                    donec.
                  </p>
                </div>
                <div className="test-1" data-aos="fade-up" data-aos-delay="250">
                  <h3>Jhon Morris</h3>
                  <img src={ts2} alt="" />
                  <h6 className="mt-2">Los Angeles</h6>
                  <ul className="starts text-center mb-2">
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star-o"></i>
                    </li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet, ligula magna at etiam aliquip
                    venenatis. Vitae sit felis donec, suscipit tortor et sapien
                    donec.
                  </p>
                </div>
                <div className="test-1" data-aos="fade-up" data-aos-delay="350">
                  <h3>Mary Deshaw</h3>
                  <img src={ts3} alt="" />
                  <h6 className="mt-2">Chicago</h6>
                  <ul className="starts text-center mb-2">
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet, ligula magna at etiam aliquip
                    venenatis. Vitae sit felis donec, suscipit tortor et sapien
                    donec.
                  </p>
                </div>
                <div className="test-1" data-aos="fade-up" data-aos-delay="450">
                  <h3>Gary Steven</h3>
                  <img src={ts4} alt="" />
                  <h6 className="mt-2">Philadelphia</h6>
                  <ul className="starts text-center mb-2">
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star-o"></i>
                    </li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet, ligula magna at etiam aliquip
                    venenatis. Vitae sit felis donec, suscipit tortor et sapien
                    donec.
                  </p>
                </div>
                <div className="test-1" data-aos="fade-up" data-aos-delay="550">
                  <h3>Cristy Mayer</h3>
                  <img src={ts5} alt="" />
                  <h6 className="mt-2">San Francisco</h6>
                  <ul className="starts text-center mb-2">
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet, ligula magna at etiam aliquip
                    venenatis. Vitae sit felis donec, suscipit tortor et sapien
                    donec.
                  </p>
                </div>
                <div className="test-1" data-aos="fade-up">
                  <h3>Ichiro Tasaka</h3>
                  <img src={ts6} alt="" />
                  <h6 className="mt-2">Houston</h6>
                  <ul className="starts text-center mb-2">
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star-o"></i>
                    </li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet, ligula magna at etiam aliquip
                    venenatis. Vitae sit felis donec, suscipit tortor et sapien
                    donec.
                  </p>
                </div>
              </Slider>
              {/* </OwlCarousel> */}
              {/* </div> */}
            </div>
          </section>
          {/* <!-- END SECTION TESTIMONIALS --> */}

          {/* <!-- START SECTION BLOG --> */}
          <section className="blog-section bg-white-1 rec-pro">
            <div className="container-fluid">
              <div className="section-title">
                <h3>Latest</h3>
                <h2>Blogs</h2>
              </div>
              <div className="news-wrap">
                <div className="row">
                  {/* {property.map((data, index) => (
                      // Convert buffer array to base64 string
        console.log(data, "item.image.data")
        const base64Image = btoa(
          new Uint8Array(data.image.data).reduce(
            (binary, byte) => binary + String.fromCharCode(byte),
            ""
          )
        );
        // console.log(base64Image, "base64Image property");
        const cleanedBase64Image = base64Image.split("base64")[1];
        console.log(cleanedBase64Image,'cleanedBase64Image');

                    <div
                      className="col-xl-6 col-md-12 col-xs-12"
                      data-aos="fade-right"
                    >
                      <div className="news-item news-item-sm">
                        <a href="blog-details.html" className="news-img-link">
                          <div className="news-item-img">
                            <img
                              className="resp-img"
                              src={portfolio1}
                              alt="blog image"
                            />
                          </div>
                        </a>
                        <div className="news-item-text">
                          <a href="blog-details.html">
                            <h3>The Real Estate News</h3>
                          </a>
                          <span className="date">
                            Jun 23, 2020 &nbsp;/&nbsp; By Admin
                          </span>
                          <div className="news-item-descr">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor.
                            </p>
                          </div>
                          <div className="news-item-bottom">
                            <a href="blog-details.html" className="news-link">
                              Read more...
                            </a>
                            <ul className="action-list">
                              <li className="action-item">
                                <i className="fa fa-heart"></i> <span>306</span>
                              </li>
                              <li className="action-item">
                                <i className="fa fa-comment"></i>{" "}
                                <span>34</span>
                              </li>
                              <li className="action-item">
                                <i className="fa fa-share-alt"></i>{" "}
                                <span>122</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="news-item news-item-sm min-last">
                        <a href="blog-details.html" className="news-img-link">
                          <div className="news-item-img">
                            <img
                              className="resp-img"
                              src={portfolio2}
                              alt="blog image"
                            />
                          </div>
                        </a>
                        <div className="news-item-text">
                          <a href="blog-details.html">
                            <h3>The Real Estate News</h3>
                          </a>
                          <span className="date">
                            Jun 23, 2020 &nbsp;/&nbsp; By Admin
                          </span>
                          <div className="news-item-descr">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor.
                            </p>
                          </div>
                          <div className="news-item-bottom">
                            <a href="blog-details.html" className="news-link">
                              Read more...
                            </a>
                            <ul className="action-list">
                              <li className="action-item">
                                <i className="fa fa-heart"></i> <span>306</span>
                              </li>
                              <li className="action-item">
                                <i className="fa fa-comment"></i>{" "}
                                <span>34</span>
                              </li>
                              <li className="action-item">
                                <i className="fa fa-share-alt"></i>{" "}
                                <span>122</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))} */}
                  {property.map((data, index) => {
                    const base64Image = btoa(
                      new Uint8Array(data.image.data).reduce(
                        (binary, byte) => binary + String.fromCharCode(byte),
                        ""
                      )
                    );
                    const cleanedBase64Image = base64Image.split("base64")[1];

                    return (
                      <div
                        className="col-xl-6 col-md-12 col-xs-12"
                        data-aos="fade-right"
                        key={index} // Add a unique key prop for each item in the map
                      >
                        <div className="news-item news-item-sm">
                          <a href="blog-details.html" className="news-img-link">
                            <div className="news-item-img">
                              <img
                                className="resp-img"
                                src={`data:image/jpeg;base64,${cleanedBase64Image}`} // Use the cleanedBase64Image as the source of the image
                                alt="blog image"
                              />
                            </div>
                          </a>
                          <div className="news-item-text">
                            <a href="blog-details.html">
                              <h3>{data.title}</h3>
                            </a>
                            <span className="date">
                              {formatDate(data.created_at)} By Admin
                            </span>
                            <div className="news-item-descr">
                              <p>{data.description}</p>
                            </div>
                            <div className="news-item-bottom">
                              <a href="blog-details.html" className="news-link">
                                Read more...
                              </a>
                              <ul className="action-list">
                                <li className="action-item">
                                  <i className="fa fa-heart"></i>{" "}
                                  <span>306</span>
                                </li>
                                <li className="action-item">
                                  <i className="fa fa-comment"></i>{" "}
                                  <span>34</span>
                                </li>
                                <li className="action-item">
                                  <i className="fa fa-share-alt"></i>{" "}
                                  <span>122</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* <div
                    className="col-xl-6 col-md-12 col-xs-12"
                    data-aos="fade-left"
                  >
                    <div className="news-item news-item-sm">
                      <a href="blog-details.html" className="news-img-link">
                        <div className="news-item-img">
                          <img
                            className="resp-img"
                            src={portfolio3}
                            alt="blog image"
                          />
                        </div>
                      </a>
                      <div className="news-item-text">
                        <a href="blog-details.html">
                          <h3>The Real Estate News</h3>
                        </a>
                        <span className="date">
                          Jun 23, 2020 &nbsp;/&nbsp; By Admin
                        </span>
                        <div className="news-item-descr">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor.
                          </p>
                        </div>
                        <div className="news-item-bottom">
                          <a href="blog-details.html" className="news-link">
                            Read more...
                          </a>
                          <ul className="action-list">
                            <li className="action-item">
                              <i className="fa fa-heart"></i> <span>306</span>
                            </li>
                            <li className="action-item">
                              <i className="fa fa-comment"></i> <span>34</span>
                            </li>
                            <li className="action-item">
                              <i className="fa fa-share-alt"></i>{" "}
                              <span>122</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="news-item news-item-sm last">
                      <a href="blog-details.html" className="news-img-link">
                        <div className="news-item-img">
                          <img
                            className="resp-img"
                            src={portfolio4}
                            alt="blog image"
                          />
                        </div>
                      </a>
                      <div className="news-item-text">
                        <a href="blog-details.html">
                          <h3>The Real Estate News</h3>
                        </a>
                        <span className="date">
                          Jun 23, 2020 &nbsp;/&nbsp; By Admin
                        </span>
                        <div className="news-item-descr">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor.
                          </p>
                        </div>
                        <div className="news-item-bottom">
                          <a href="blog-details.html" className="news-link">
                            Read more...
                          </a>
                          <ul className="action-list">
                            <li className="action-item">
                              <i className="fa fa-heart"></i> <span>306</span>
                            </li>
                            <li className="action-item">
                              <i className="fa fa-comment"></i> <span>34</span>
                            </li>
                            <li className="action-item">
                              <i className="fa fa-share-alt"></i>{" "}
                              <span>122</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
          {/* <!-- END SECTION BLOG --> */}

          {/* <!-- START FOOTER --> */}

          <Footer />
          <a data-scroll href="#wrapper" className="go-up">
            <i className="fa fa-angle-double-up" aria-hidden="true"></i>
          </a>
          {/* <!-- END FOOTER --> */}
        </>
      )}
      {/* <!--register form end --> */}

      {/* <!-- START PRELOADER --> */}
      {loader && (
        <div id="preloader">
          <div id="status">
            <div className="status-mes"></div>
          </div>
        </div>
      )}
      {/* <!-- END PRELOADER --> */}
    </div>
  );
};

export default Home;
