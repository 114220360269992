import React, { useMemo, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";
// Custom components
import Card from "components/card/Card";
import TableHeader from "components/tableHeader";
import AdminSidebar from "layouts/admin";
import { MDBDataTableV5 } from "mdbreact";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IconButton } from "@chakra-ui/react";
import "react-responsive-modal/styles.css";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import moment from "moment";

export default function Approved({}) {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "id",
        field: "id",
        width: 270,
      },
      {
        label: "status",
        field: "status",
        width: 150,
      },
      {
        label: "full Name",
        field: "fullName",
        width: 150,
      },
      {
        label: "start Date",
        field: "startDate",
        width: 150,
      },
      {
        label: "end Date",
        field: "endDate",
        width: 150,
      },
      {
        label: "price Per Day",
        field: "pricePerDay",
        width: 150,
      },
      {
        label: "deposit",
        field: "deposit",
        width: 150,
      },
      {
        label: "cleaning Fees",
        field: "cleaningFees",
        width: 150,
      },
      {
        label: "Deposit Status",
        field: "depositStatus",
        width: 100,
      },
    ],
  });

  const [deposit, setDeposite] = useState({
    deposit: "",
  });

  const depositePayment = (data) => {
    setOpen(true);
    setDeposite(data);
    console.log(data);
  };

  const AddRefund = (values) => {
    console.log(values, "values");
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/refund-deposit`, {
        xRefNum: values.adminXrefNum,
        xAmount: values.deposit,
        bookingId: values.id,
      })
      .then((response) => {
        getGalleryData();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Deposit Refund successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        // Optionally, you can update the datatable state to reflect the updated data without the deleted blog.
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getGalleryData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/requested-bookings?status=approved`)
      .then((response) => {
        console.log(response, "response");
        const data = response.data.data.map((item) => {
          // Convert buffer array to base64 string
          // console.log(item, "item");

          return {
            id: item.id,
            status: item.status,
            fullName: item.fullName,
            startDate: moment(item.startDate).format("DD/MM/YYYY"),
            endDate: moment(item.endDate).format("DD/MM/YYYY"),
            pricePerDay: item.pricePerDay,
            deposit: item.deposit,
            cleaningFees: item.cleaningFees,
            depositStatus: (
              <div>
                {item.isDepositeRefunded ? (
                  item.refunfXrefNum
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      depositePayment(item);
                    }}
                  >
                    Pay Deposite
                  </button>
                )}
              </div>
            ),
          };
        });

        setDatatable((prevState) => ({
          ...prevState,
          rows: data,
        }));
      });
  };

  useEffect(() => {
    getGalleryData();
  }, []);

  return (
    <>
      <AdminSidebar>
        <Card>
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={datatable}
          />
        </Card>
        <Modal open={open} onClose={onCloseModal} center>
          <Formik
            initialValues={deposit}
            onSubmit={(values, { resetForm }) => {
              AddRefund(values);
              onCloseModal();
              resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <>
                <div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Deposite</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Deposite"
                      name="deposit"
                      value={values.deposit}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Pay
                </button>
              </>
            )}
          </Formik>
        </Modal>
      </AdminSidebar>
    </>
  );
}
