import React, { useState, useEffect } from "react";
import Footer from "../common/footer";
import Header from "../common/header";
import LeftBar from "../common/leftbar";
import UserProfile from "./UserProfile";
import ChangePassword from "./ChangePassword";
import MyPayments from "./MyPayments";
import MyComplain from "./MyComplain";
import Wishlist from "./Wishlist";
import Faq from "./Faq";
import axios from "axios";
import "../common/css/jquery-ui.css";
import "../common/css/fontawesome-all.min.css";
import "../common/css/fontawesome-5-all.min.css";
import "../common/css/font-awesome.min.css";
import "../common/css/search.css";
import "../common/css/animate.css";
import "../common/css/aos2.css";
import "../common/css/swiper.min.css";
import "../common/css/magnific-popup.css";
import "../common/css/lightcase.css";
import "../common/css/bootstrap.min.css";
import "../common/css/menu.css";
import "../common/css/default.css";
import "../common/css/styles.css";

const Profile = () => {
  const [currentTab, setCurrentTab] = useState("myProfile");
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    getUserData();
  }, []);
  const getUserData = () => {
    axios
      .get("${process.env.REACT_APP_BASE_URL}/user/" + localStorage.getItem("userid"))
      .then((data) => {
        setUserData(data.data);
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
      });
  };
  return (
    <div className="inner-pages int_white_bg hd-white">
      <LeftBar />
      <Header />
      <section className="headings">
        <div className="text-heading text-center">
          <div className="container">
            <h1>My Dashboard</h1>
            <h2>
              <a href="index.html">Home </a> &nbsp;/&nbsp; Dashboard
            </h2>
          </div>
        </div>
      </section>
      {/* <!-- END SECTION HEADINGS --> */}
      <section className="services-home rec-pro pt-5">
        <div className="container">
          <div id="tsum-tabs">
            <main>
              <div className="container-row">
                <div
                  onClick={() => setCurrentTab("myProfile")}
                  className={currentTab === "myProfile" ? "active" : ""}
                >
                  {/* <input id="tab1" type="radio" style={{display: 'none'}} name="tabs" checked /> */}
                  <label htmlFor="tab1">My Profile</label>
                </div>
                <div
                  onClick={() => setCurrentTab("changePassword")}
                  className={currentTab === "changePassword" ? "active" : ""}
                >
                  {/* <input id="tab2" type="radio" style={{display: 'none'}} name="tabs" /> */}
                  <label htmlFor="tab2">Change Password</label>
                </div>
                <div
                  onClick={() => setCurrentTab("myPayments")}
                  className={currentTab === "myPayments" ? "active" : ""}
                >
                  {/* <input id="tab3" type="radio" style={{display: 'none'}} name="tabs" /> */}
                  <label htmlFor="tab3">My Payments</label>
                </div>
                <div
                  onClick={() => setCurrentTab("myComplain")}
                  className={currentTab === "myComplain" ? "active" : ""}
                >
                  {/* <input id="tab4" type="radio" style={{display: 'none'}} name="tabs" /> */}
                  <label htmlFor="tab4">My Complain</label>
                </div>
                <div
                  onClick={() => setCurrentTab("wishlist")}
                  className={currentTab === "wishlist" ? "active" : ""}
                >
                  {/* <input id="tab5" type="radio" style={{display: 'none'}} name="tabs" /> */}
                  <label htmlFor="tab5">Wishlist</label>
                </div>
                <div
                  onClick={() => setCurrentTab("faq")}
                  className={currentTab === "faq" ? "active" : ""}
                >
                  {/* <input id="tab6" type="radio" style={{display: 'none'}} name="tabs" /> */}
                  <label htmlFor="tab6">FAQ</label>
                </div>
                {/* <div
                  onClick={() => setCurrentTab("logout")}
                  className={currentTab === "logout" ? "active" : ""}
                >
                  <label htmlFor="tab7">Logout</label>
                </div> */}
              </div>
              {currentTab === "myProfile" ? (
                <UserProfile userData={userData} />
              ) : currentTab === "changePassword" ? (
                <ChangePassword />
              ) : currentTab === "myPayments" ? (
                <MyPayments />
              ) : currentTab === "myComplain" ? (
                <MyComplain />
              ) : currentTab === "wishlist" ? (
                <Wishlist />
              ) : (
                currentTab === "faq" && <Faq />
              )}
            </main>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Profile;
