import React, { useMemo, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import { EditIcon, DeleteIcon, CloseIcon } from "@chakra-ui/icons";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";
// Custom components
import Card from "components/card/Card";
import TableHeader from "components/tableHeader";
import AdminSidebar from "layouts/admin";
import { MDBDataTableV5 } from "mdbreact";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IconButton } from "@chakra-ui/react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Box, Image } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function ViewFlat({}) {
  const location = useLocation();
  const { id } = location.state;
  const [flatData, setFlatData] = useState([]);

  const fetchFlateData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/flat/${id}`
      );

      const jsonData = await response.json();
      //   console.log(jsonData.flatData);
      setFlatData(jsonData.flatData[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const images = [
    "/images/slider1.jpeg",
    "/images/slider2.jpeg",
    // Add more image URLs here
  ];

  useEffect(() => {
    fetchFlateData();
  }, []);

  return (
    <>
      <AdminSidebar>
        <Card>
          <Box>
            {console.log(flatData, "flatData")}
            <Carousel showArrows={true} infiniteLoop={true}>
              {flatData?.images?.map((image, index) => {
                // const base64Image = btoa(
                //   new Uint8Array(image.data).reduce(
                //     (binary, byte) => binary + String.fromCharCode(byte),
                //     ""
                //   )
                // );
                return (
                  <div key={index}>
                    <Image src={image} alt={`Slide ${index}`} />

                    <div className="row">
                      <div className="col-md-4 mt-4 d-flex">
                        <p className="view-text"> Bathrooms:</p>
                        <p className="view-data mx-2">{flatData?.bathrooms}</p>
                      </div>
                      <div className="col-md-4 mt-4 d-flex">
                        <p className="view-text"> Beds:</p>
                        <p className="view-data mx-2">{flatData?.beds}</p>
                      </div>
                      <div className="col-md-4 mt-4 d-flex">
                        <p className="view-text"> cleaningFees:</p>
                        <p className="view-data mx-2">
                          {flatData?.cleaningFees}
                        </p>
                      </div>
                      <div className="col-md-4 mt-4 d-flex">
                        <p className="view-text"> guests:</p>
                        <p className="view-data mx-2">{flatData?.guests}</p>
                      </div>
                      <div className="col-md-4 mt-4 d-flex">
                        <p className="view-text"> price:</p>
                        <p className="view-data mx-2">{flatData?.price}</p>
                      </div>
                      <div className="col-md-4 mt-4 d-flex">
                        <p className="view-text"> unitNo:</p>
                        <p className="view-data mx-2">{flatData?.unitNo}</p>
                      </div>
                      <div className="col-md-4 mt-4 d-flex">
                        <p className="view-text"> unitType:</p>
                        <p className="view-data mx-2">{flatData?.unitType}</p>
                      </div>
                      <div className="col-md-6 mt-4 d-flex">
                        <p className="view-text"> description:</p>
                        <p className="view-data mx-2">
                          {flatData?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </Box>
        </Card>
      </AdminSidebar>
    </>
  );
}
