import React, { useState } from "react";
import axios from "axios";
const MyComplain = () => {
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [unitNumber, setUnitNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errMsg, setMsg] = useState("");

  const clearData = () => {
    setName("");
    setMobileNumber("");
    setUnitNumber("");
    setSubject("");
    setMessage("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // perform submit action here
    axios
      .post("${process.env.REACT_APP_BASE_URL}/complaints", {
        name,
        mobile_number: mobileNumber,
        unit_number: unitNumber,
        subject: subject,
        message: message,
      })
      .then((data) => {
        console.log(data,'data');
        clearData();
        // setUserData(data.data);
        // setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          setMsg(err.response.data.message);
          console.log(err.response.data.message,'err');
        } else {
          clearData();
        }
      });
  };

  return (
    <section>
      <br />
      <div className="my-properties my-address" style={{ padding: "5%" }}>
        <form>
          {/* <div className="row">
            <div className="col-lg-12 ">
              <div className="form-group name">
                <label className="titleinput">Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Enter Your Name"
                />
              </div>
            </div>
            <div className="col-lg-12 ">
              <div className="form-group name">
                <label className="titleinput">Mobile Number</label>
                <input
                  type="number"
                  name="name"
                  className="form-control"
                  placeholder="Enter Your Number"
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group email">
                <label className="titleinput">Unit Number</label>
                <input
                  type="text"
                  name="unit-number"
                  className="form-control"
                  placeholder="Enter Unit Number"
                />
              </div>
            </div>
            <div className="col-lg-12 ">
              <div className="form-group subject">
                <label className="titleinput">Complain Subject</label>
                <input
                  type="text"
                  name="subject"
                  className="form-control"
                  placeholder="Complain Subject"
                />
              </div>
            </div>
            <div className="col-lg-12 ">
              <div className="form-group subject">
                <label className="titleinput">Complain Message</label>
                <textarea
                  className="form-control textarea-custom input-full"
                  id="ccomment"
                  name="message"
                  required
                  rows="8"
                  placeholder="Message"
                ></textarea>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="send-btn mt-2">
                <button type="submit" className="btn btn-common">
                  Send Complain
                </button>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-12 ">
              <div className="form-group name">
                <label className="titleinput">Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Enter Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-12 ">
              <div className="form-group name">
                <label className="titleinput">Mobile Number</label>
                <input
                  type="number"
                  name="mobileNumber"
                  className="form-control"
                  placeholder="Enter Your Number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group email">
                <label className="titleinput">Unit Number</label>
                <input
                  type="text"
                  name="unit-number"
                  className="form-control"
                  placeholder="Enter Unit Number"
                  value={unitNumber}
                  onChange={(e) => setUnitNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-12 ">
              <div className="form-group subject">
                <label className="titleinput">Complain Subject</label>
                <input
                  type="text"
                  name="subject"
                  className="form-control"
                  placeholder="Complain Subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-12 ">
              <div className="form-group subject">
                <label className="titleinput">Complain Message</label>
                <textarea
                  className="form-control textarea-custom input-full"
                  id="ccomment"
                  name="message"
                  required
                  rows="8"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="send-btn mt-2">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btn btn-common"
                >
                  Send Complain
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default MyComplain;
