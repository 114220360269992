import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";

const BuldingDetails = () => {
  const location = useLocation();
  const { floorNo, values } = location.state;
  const [data, setData] = useState([]);
  const [error, setIsError] = useState("");
  const history = useHistory();

  const getAvailableBulding = async () => {
    // console.log(values, "values");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/check-available-flats`,
        { ...values, floor: floorNo }
      );
      setData(res.data.data);
      //   console.log(res.data.data, "data");
    } catch (error) {
      setIsError(error.message);
      console.log(error);
    }
  };

  const navigateFlateDetails = (data) => {
    history.push("/flatDetails", { data });
  };

  useEffect(() => {
    getAvailableBulding();
  }, []);

  return (
    <div className="main">
      <div className="container">
        <div className="row mt-5">
          {data.map((item, index) => (
            <div className="col-md-3 mt-3" id="imageContainer">
              <div className="hover-content">
                <p>Unit No: {item.unitNo}</p>
                <p>Floor ID: {item.floorId}</p>
                <p>Unit Type: {item.unitType}</p>
                <p>cleaningFees: {item.cleaningFees}</p>
                <p>price: {item.price}</p>
                <p>guests: {item.guests}</p>
                <p>newPrice: {item.newPrice}</p>
                <p>description: {item.description}</p>

                {item.isClickable && (
                  <button
                    className="btn btn-primary"
                    onClick={() => navigateFlateDetails(item)}
                  >
                    Book Now
                  </button>
                )}
              </div>
              <img
                src="/images/slider1.jpeg"
                alt="image"
                style={{ height: "20rem" }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BuldingDetails;
