import { useState, useEffect } from "react";
import Footer from "../common/footer";
import Header from "../common/header";
import LeftBar from "../common/leftbar";
import axios from "axios";
import i1 from "../common/images/site/0.jpg";
import i2 from "../common/images/site/1.jpg";
import i3 from "../common/images/site/1 (1).jpg";
import i4 from "../common/images/site/1 (2).jpg";
import i5 from "../common/images/site/2.jpg";
import i6 from "../common/images/site/4 (1).jpg";
import i7 from "../common/images/site/4.jpg";
import i8 from "../common/images/site/drone1.png";
import i9 from "../common/images/site/drone3.png";

import "../common/css/jquery-ui.css";
import "../common/css/fontawesome-all.min.css";
import "../common/css/fontawesome-5-all.min.css";
import "../common/css/font-awesome.min.css";
import "../common/css/search.css";
import "../common/css/animate.css";
import "../common/css/aos2.css";
import "../common/css/swiper.min.css";
import "../common/css/magnific-popup.css";
import "../common/css/lightcase.css";
import "../common/css/bootstrap.min.css";
import "../common/css/menu.css";
import "../common/css/default.css";
import "../common/css/styles.css";

const galleryImages = [
  {
    image: i1,
    type: "Living",
  },
  {
    image: i2,
    type: "Exterior",
  },
  {
    image: i3,
    type: "Living",
  },
  {
    image: i4,
    type: "Living",
  },
  {
    image: i5,
    type: "Exterior",
  },
  {
    image: i6,
    type: "Bedroom",
  },
  {
    image: i7,
    type: "Exterior",
  },
  {
    image: i8,
    type: "Exterior",
  },
  {
    image: i9,
    type: "Exterior",
  },
];

const Gallery = () => {
  const [active, setActive] = useState("All");
  const [galleryCategoryList, setGalleryCategoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [galleryList, setGalleryList] = useState([]);

  useEffect(() => {
    getGalleryData();
    getGalleryCategoryData();
  }, []);
  const getGalleryCategoryData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/gallery_category`)
      .then((data) => {
        setGalleryCategoryList(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getGalleryData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/gallery`)
      .then((data) => {
        setGalleryList(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <div className="inner-pages int_white_bg">
      <LeftBar />
      <Header />
      <section className="headings">
        <div className="text-heading text-center">
          <div className="container">
            <h1>Our Gallery</h1>
            <h2>
              <a href="index.html">Home </a> &nbsp;/&nbsp; Gallery
            </h2>
          </div>
        </div>
      </section>
      {/* <!-- END SECTION HEADINGS --> */}

      <section
        className="services-home rec-pro"
        style={{ paddingBottom: "10% !important" }}
      >
        <div className="container" style={{ maxWidth: "85%" }}>
          <div className="wrapper">
            {/* <!-- filter Items --> */}
            <nav>
              <div className="items">
                <span
                  onClick={() => {
                    setActive("All");
                  }}
                  className={`item ${active === "All" ? "active" : ""}`}
                >
                  All
                </span>
                {galleryCategoryList.map((i) => (
                  <span
                    onClick={() => {
                      setActive(i.name);
                    }}
                    className={`item ${active === i.name ? "active" : ""}`}
                  >
                    {i.name}
                  </span>
                ))}
              </div>
            </nav>
            {/* <!-- filter Images --> */}
            <div className="gallery">
              {galleryList
                .filter((i) => i.category_name === active || active === "All")
                .map((i) => (
                  <div className="image">
                    <span>
                      <img className="imggal" src={i.file} alt="" />
                    </span>
                  </div>
                ))}
            </div>
          </div>
          {/* <!-- fullscreen img preview box --> */}
          <div className="preview-box">
            <div className="details">
              <span className="title">
                Image Category: <p></p>
              </span>
              <span className="icon fas fa-times"></span>
            </div>
            <div className="image-box">
              <img src="" alt="" />
            </div>
          </div>
          <div className="shadow"></div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Gallery;
