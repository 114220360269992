import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router-dom";
import LeftBar from "../common/leftbar";
import Header from "../common/header";
import Footer from "../common/footer";
import portfolio4 from "../common/images/site/1.jpg";
import drone1 from "../common/images/site/drone1.png";
import axios from "axios";

import "../common/css/jquery-ui.css";
import "../common/css/fontawesome-all.min.css";
import "../common/css/fontawesome-5-all.min.css";
import "../common/css/font-awesome.min.css";
import "../common/css/search.css";
import "../common/css/animate.css";
import "../common/css/aos2.css";
import "../common/css/swiper.min.css";
import "../common/css/magnific-popup.css";
import "../common/css/lightcase.css";
import "../common/css/bootstrap.min.css";
import "../common/css/menu.css";
import "../common/css/default.css";
import "../common/css/styles.css";

const floors = [
  {
    floorNumber: 1,
    rooms: [
      {
        roomNumber: 101,
        price: 100,
        available: true,
        x: 140,
        y: 270,
        width: 150,
        height: 40,
      },
      {
        roomNumber: 201,
        price: 120,
        available: true,
        x: 140,
        y: 310,
        width: 150,
        height: 40,
      },
      {
        roomNumber: 301,
        price: 150,
        available: true,
        x: 140,
        y: 350,
        width: 150,
        height: 40,
      },
    ],
  },
  // {
  //   floorNumber: 2,
  //   rooms: [
  //     {
  //       roomNumber: 201,
  //       price: 200,
  //       available: true,
  //       x: 150,
  //       y: 150,
  //       width: 50,
  //       height: 50,
  //     },
  //     {
  //       roomNumber: 202,
  //       price: 220,
  //       available: true,
  //       x: 250,
  //       y: 250,
  //       width: 50,
  //       height: 50,
  //     },
  //     {
  //       roomNumber: 203,
  //       price: 250,
  //       available: true,
  //       x: 350,
  //       y: 350,
  //       width: 50,
  //       height: 50,
  //     },
  //   ],
  // },
];


const RoomDetails = ({ roomNumber, selectedRoomData }) => {
  const roomData = floors
    .reduce((acc, floor) => {
      return acc.concat(floor.rooms);
    }, [])
    .find((room) => room.roomNumber === roomNumber);
    const addBooking = () => {
      // setIsAddModalOpen(false);
      let booking = {
        room_id:2,
        start_date: "2023-05-14",
        end_date: "2023-05-15",
        user_id:4
      }
      axios
        .post("${process.env.REACT_APP_BASE_URL}/bookings", booking)
        .then((data) => {
          // getAmenitiesData();
          // setAddData({ title: "", description: "" });
        })
        .catch((err) => {});
    };
  return (
    <div
      // className="room-details-card"
      style={{
        left: selectedRoomData?.x + selectedRoomData?.width,
        top: selectedRoomData?.y,
        position: "absolute",
        backgroundColor: "#fff",
        padding: 20,
        backgroundColor: "#fff",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
        borderRadius: 5,
        zIndex: 10,
        width: 300,
        border: "1px solid #cccccc",
      }}
    >
      <h2
        style={{
          marginTop: 0,
          fontSize: 24,
          fontWeight: "bold",
        }}
      >
        Room {roomNumber}
      </h2>
      <p
        style={{
          marginTop: 10,
          fontSize: 16,
          lineHeight: 1.4,
        }}
      >
        Price: {roomData.price}
      </p>
      <p
        style={{
          marginTop: 10,
          fontSize: 16,
          lineHeight: 1.4,
        }}
      >
        {roomData.available ? "Available" : "Not Available"}
      </p>
      <button
        style={{
          marginTop: 10,
          fontSize: 16,
          backgroundColor: "blue",
          color: "#fff",
          padding: "10px 20px",
          border: "none",
          borderRadius: 5,
          cursor: "pointer",
        }}
        onClick={()=>{addBooking()}}
      >
        Book Now
      </button>
    </div>
  );
};

const HighlightImage = ({ roomNumber, selectedRoomData }) => {
  const roomData = floors
    .reduce((acc, floor) => {
      return acc.concat(floor.rooms);
    }, [])
    .find((room) => room.roomNumber === roomNumber);

  return (
    <div
      // className="room-details-card"
      style={{
        left: selectedRoomData?.x,
        top: selectedRoomData?.y + 100,
        position: "absolute",
        // backgroundColor: "#fff",
        // padding: 20,
        // backgroundColor: "#fff",
        // boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
        // borderRadius: 5,
        // zIndex: 10,
        width: selectedRoomData?.width,
        height: selectedRoomData?.height,
        backgroundColor: "rgb(219 149 149 / 50%)",
        zIndex: 1,
        // border: "1px solid #cccccc",
      }}
    ></div>
  );
};

const Booking = () => {
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedRoomData, setSelectedRoomData] = useState(null);
  const [loader, setLoader] = useState(false);

 

  const handleRoomClick = (roomNumber, coords) => {
    setSelectedRoom(roomNumber);
    setSelectedRoomData(coords);
    // const roomData = floors.reduce((acc, floor) => {
    //   return acc.concat(floor.rooms);
    // }, []).find((room) => room.roomNumber === roomNumber);

    // const { x, y, width, height } = roomData;
    // const image = document.getElementById('property-map');

    // if (image) {
    //   const rect = image.getBoundingClientRect();
    //   const xRatio = image.width / rect.width;
    //   const yRatio = image.height / rect.height;
    //   const imageX = rect.left;
    //   const imageY = rect.top;

    //   const highlight = document.createElement('div');
    //   highlight.style.position = 'absolute';
    //   highlight.style.left = `${(x - imageX) * xRatio}px`;
    //   highlight.style.top = `${(y - imageY) * yRatio}px`;
    //   highlight.style.width = `${width * xRatio}px`;
    //   highlight.style.height = `${height * yRatio}px`;
    //   highlight.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';
    //   highlight.style.zIndex = '999';

    //   image.parentElement.appendChild(highlight);

    //   setTimeout(() => {
    //     image.parentElement.removeChild(highlight);
    //   }, 2000);
    // }
  };

  const handleOutsideClick = () => {
    setSelectedRoom(null);
    setSelectedRoomData(null);
  };

  return (
    <div
      className="inner-pages int_white_bg hd-white"
      style={{
        backgroundColor: "white",
      }}
    >
      {!loader && (
        <>
          <LeftBar />
          {/* <!-- START SECTION HEADINGS --> */}
          {/* <!-- Header Container */}
          {/* ================================================== --> */}
          <Header />

          <div>
            <img
              // src="https://images.pexels.com/photos/36366/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              src={drone1}
              alt="Property Map"
              useMap="#map"
              onClick={handleOutsideClick}
              width="800"
              height="600"
            />
            <map name="map">
              {floors.map((floor) => (
                <React.Fragment key={floor.floorNumber}>
                  {floor.rooms.map((room) => (
                    <area
                      key={room.roomNumber}
                      shape="rect"
                      coords={`${room.x},${room.y},${room.x + room.width},${
                        room.y + room.height
                      }`}
                      style={{
                        cursor: "pointer",
                        opacity: selectedRoom === room.roomNumber ? 0.5 : 0.2,
                      }}
                      // onMouseOver={() => setSelectedRoom(room.roomNumber)}
                      // onMouseOut={() => setSelectedRoom(null)}
                      onClick={() => handleRoomClick(room.roomNumber, room)}
                    />
                  ))}
                </React.Fragment>
              ))}
            </map>
            {selectedRoom && (
              <RoomDetails
                roomNumber={selectedRoom}
                selectedRoomData={selectedRoomData}
              />
            )}
            {selectedRoom && (
              <HighlightImage
                roomNumber={selectedRoom}
                selectedRoomData={selectedRoomData}
              />
            )}
          </div>

          {/* <!-- START FOOTER --> */}

          <Footer />
          <a data-scroll href="#wrapper" className="go-up">
            <i className="fa fa-angle-double-up" aria-hidden="true"></i>
          </a>
          {/* <!-- END FOOTER --> */}
        </>
      )}
      {/* <!--register form end --> */}

      {/* <!-- START PRELOADER --> */}
      {loader && (
        <div id="preloader">
          <div id="status">
            <div className="status-mes"></div>
          </div>
        </div>
      )}
      {/* <!-- END PRELOADER --> */}
    </div>
  );
};

export default Booking;
