import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
// import TimePicker from "react-time-picker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AdminSidebar from "layouts/admin";

// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import React, { useMemo, useEffect, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";
// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import moment from "moment";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
export default function ColumnsTable(props) {
  const { columnsData, tableData } = props;
  const cancelRef = React.useRef();

  const columns = useMemo(() => columnsData, [columnsData]);
  const [bookingList, setBookingList] = useState([
    {
      bookingId: 1,
      capacity: 3,
      end_date: "2023-03-11T18:30:00.000Z",
      end_time: "20:45:00",
      name: "Alexandra",
      floor_no: "2",
      id: 2,
      is_confirmed: 0,
      price: "600$",
      room_id: 2,
      room_no: "201",
      start_date: "2023-03-10T18:30:00.000Z",
      start_time: "20:44:00",
      status: "sold",
      user_id: 2,
    },
    {
      bookingId: 1,
      capacity: 3,
      end_date: "2023-03-11T18:30:00.000Z",
      end_time: "20:45:00",
      name: "Andrea",
      floor_no: "2",
      id: 2,
      is_confirmed: 0,
      price: "600$",
      room_id: 2,
      room_no: "201",
      start_date: "2023-03-10T18:30:00.000Z",
      start_time: "20:44:00",
      status: "sold",
      user_id: 2,
    },
  ]);
  const data = useMemo(() => bookingList, [bookingList]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    // http://localhost:8000
    // http://localhost:8000
    axios
      .get("${process.env.REACT_APP_BASE_URL}/bookings")
      .then((data) => {
        setBookingList(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleEditClick = (obj) => {
    setIsModalOpen(true);
    setEditData({
      ...obj,
      start_date: moment(obj.start_date).format("YYYY-MM-DD"),
      end_date: moment(obj.end_date).format("YYYY-MM-DD"),
    });
  };

  const handleDeleteClick = (id) => {
    setDeleteOpen(true);
    setDeleteId(id);
  };
  const onDeleteClose = () => {
    setDeleteOpen(false);
    setDeleteId(null);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;
  const onChange = (key, value) => {
    setEditData({ ...editData, [key]: value });
  };

  const handleDateTimeChange = (type, value) => {
    if (type === "start") {
      let start_date = value ? value.split("T")[0] : "",
        start_time = value ? value.split("T")[1] : "";

      setEditData({ ...editData, start_date, start_time });
    } else {
      let end_date = value ? value.split("T")[0] : "",
        end_time = value ? value.split("T")[1] : "";

      setEditData({ ...editData, end_date, end_time });
    }
    // Do something with the combined date and time value
  };

  const handleTimeChange = (key, value) => {
    setEditData({ ...editData, key: moment(value).format("HH:mm:ss") });
    // Do something with the combined date and time value
  };
  const onDelete = () => {
    axios
      .delete("${process.env.REACT_APP_BASE_URL}/bookings/" + deleteId)
      .then((data) => {
        getData();
        onDeleteClose();
      })
      .catch((err) => {
        console.log("err --------------- ", err);
        onDeleteClose();
      });
  };
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return localStorage.getItem("token") === "aosijdhfqh4982h342938fhw398we9rfhw9e8rhc9werf9wuhdoiadifuahsdifidufhwer89h39ufhw93r8uhf" ? (
    <AdminSidebar>
      {loading ? (
        <Flex justify="center">
          <Spinner size="lg" color="gray.500" thickness="4px" />
        </Flex>
      ) : (
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          {/* <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          4-Column Table
        </Text>
        <Menu />
      </Flex> */}
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "NAME") {
                        data = (
                          <Flex align="center">
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.row.original.name}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header === "PROGRESS") {
                        data = (
                          <Flex align="center">
                            <Text
                              me="10px"
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}%
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header === "QUANTITY") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "FROM DATE") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {moment(
                              cell.row.original.start_date +
                                " " +
                                cell.row.original.start_time,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD-MM-YYYY HH:mm")}
                          </Text>
                        );
                      } else if (cell.column.Header === "TO DATE") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {moment(
                              cell.row.original.end_date +
                                " " +
                                cell.row.original.end_time,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD-MM-YYYY HH:mm")}
                          </Text>
                        );
                      } else if (cell.column.Header === "ROOM NO") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "FLOOR NO") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "PRICE") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "ACTION") {
                        data = (
                          <>
                            <IconButton
                              icon={<EditIcon />}
                              aria-label="Edit"
                              size="sm"
                              colorScheme="blue"
                              mr="2"
                              onClick={() => {
                                handleEditClick(cell.row.original);
                              }}
                            />
                            <IconButton
                              icon={<DeleteIcon />}
                              aria-label="Delete"
                              size="sm"
                              colorScheme="blue"
                              mr="2"
                              onClick={() => {
                                handleDeleteClick(cell.row.original.bookingId);
                              }}
                            />
                          </>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {isModalOpen && (
            // Chakra UI modal component with input fields
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Booking</ModalHeader>
                <ModalBody>
                  <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input
                      type="text"
                      value={editData.name}
                      onChange={(e) => {
                        onChange("name", e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Start Date and Time</FormLabel>

                    <input
                      type="datetime-local"
                      value={editData.start_date + " " + editData.start_time}
                      onChange={(e) => {
                        handleDateTimeChange("start", e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>End Date and Time</FormLabel>

                    <input
                      type="datetime-local"
                      value={editData.end_date + " " + editData.end_time}
                      onChange={(e) => {
                        handleDateTimeChange("end", e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Price</FormLabel>
                    <Input
                      type="text"
                      value={editData.price}
                      onChange={(e) => {
                        onChange("price", e.target.value);
                      }}
                    />
                  </FormControl>
                  {/* Add more input fields as needed */}
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => setIsModalOpen(false)}
                  >
                    Save
                  </Button>
                  <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}

          <AlertDialog
            isOpen={isDeleteOpen}
            leastDestructiveRef={cancelRef}
            onClose={onDeleteClose}
          >
            <AlertDialogOverlay />
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Item
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete this item?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onDeleteClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={onDelete} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </Card>
      )}
    </AdminSidebar>
  ) : (
    <Redirect to="/admin" />
  );
}
